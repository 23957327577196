/**
 * @author Jonathan Kryvenchuk
 * @author Omer Ahmed
 * @version MAR-29-2022
 * @description Arrows Repeat Icon
 */

//React
import React from "react";

//Prop Types
import PropTypes from "prop-types";

function RepeatIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 451.36 375.91"
            width={props.width}
            height={props.height}
            fill={props.color}
            className={"m-0 p-0 " + props.className}
        >
            <path
                d="M403.87,125.24,297.08,62l.06,41.25c-36.33.46-81.58,4.77-108.26,9.76-26,4.86-79,14.77-111.9,44.31-25.7,23.06-52.66,59.07-52.66,110.76C24.32,305,44.6,335.78,50.65,342l39.5-44.3C77,275.5,70.4,260.74,83.56,231.2c17.32-38.84,68.41-57.26,105.32-66.46,46.65-11.63,68.89-14.07,108.33-11l0,35Z"
                transform="translate(-24.32 -62.04)"
            />
            <path
                d="M96.13,374.76,202.92,438l-.06-41.25c36.33-.46,81.58-4.77,108.26-9.76,26-4.86,79-14.77,111.9-44.31,25.7-23.06,52.66-59.07,52.66-110.76,0-36.92-20.28-67.66-26.33-73.84l-39.5,44.3c13.17,22.16,19.75,36.92,6.59,66.46-17.32,38.84-68.41,57.26-105.32,66.46-46.65,11.63-68.89,14.07-108.33,11l0-35Z"
                transform="translate(-24.32 -62.04)"
            />
        </svg>
    );
}

RepeatIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
};

RepeatIcon.defaultProps = {
    color: "black",
    width: "32",
    height: "32",
    className: "",
};

export default RepeatIcon;
