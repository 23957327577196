/**
 * @author Zachary Fedorak
 * @author Afi Ali
 * @author Robert Kelly
 * @author Jonathan Kryvenchuk
 * @version APR-12-2022
 * @visibleName Blog Page
 * @description This page will contain blog posts that users may choose to expand if wanting to read the article.
 */

//Bootstrap
import Container from "react-bootstrap/Container";
import { Card } from "react-bootstrap";

//import
import DataFetcher from "../components/dataFetcher/DataFetcher";

// Markdown parser
import MarkdownIt from "markdown-it";

//Icons
import CaretDownFilled from "../components/icons/CaretDownFilled";
import CaretUpFilled from "../components/icons/CaretUpFilled";

//Css
import "./Blog.css";

function BlogPage() {
    let posts = DataFetcher("Posts");
    const mdParser = new MarkdownIt();
    let btnViewClass = "";

    /**
     * Select all tags inside post and toggle d-none (Bootstrap class) on every tag but the first
     * @see https://getbootstrap.com/docs/5.1/utilities/display/#hiding-elements
     * @param id parent element id
     */
    function collapsePost(id) {
        let tagList = document.querySelectorAll(`#${id} > *`);
        tagList.forEach((tag, i) => {
            if (i !== 0) {
                tag.classList.toggle("d-none");
            }
            if (tagList.length <= 1) {
                tag.classList.add("mb-0");
            }
        });
        return tagList.length;
    }

    /**
     * Convert Markdown to string, then set div#formatHTML innerHTML to add class names using collapsePost()
     * @param post Post Markdown text from database
     * @param i Post index
     * @returns HTML to be rendered
     */
    function setInnerHTML(post) {
        let body = mdParser.render(post.Body);
        let formatHTMLElement = document.querySelector("#formatHTML");

        formatHTMLElement.innerHTML = body;
        if (collapsePost("formatHTML") <= 1) {
            btnViewClass = "d-none";
        } else {
            btnViewClass = "";
        }
        body = formatHTMLElement.innerHTML;
        formatHTMLElement.innerHTML = "";
        return {
            __html: body,
        };
    }

    return (
        <main className="flex-fill blog-page">
            <Container className="max-container mb-3" fluid>
                <h1 className="text-center mt-5 mb-0 text-uppercase">Blog</h1>
                {posts.length > 0 ? (
                    posts.map((post, i) => {
                        if (post.Published) {
                            return (
                                <Card
                                    className="col-md-8 mx-auto mt-4 cardBorder"
                                    key={post.key}
                                >
                                    <Card.Title className="cardTitle">
                                        {post.Title}
                                    </Card.Title>
                                    <hr />

                                    <Card.Body className="cardBody post">
                                        <div
                                            className="post-body"
                                            id={"postBody" + i}
                                            dangerouslySetInnerHTML={setInnerHTML(
                                                post
                                            )}
                                        />
                                        <a
                                            href=""
                                            id={"postViewBtn" + i}
                                            className={btnViewClass}
                                            onClick={(event) => {
                                                event.preventDefault();

                                                if (
                                                    document.querySelector(
                                                        `#postBody${i}`
                                                    ) !== undefined
                                                ) {
                                                    collapsePost(
                                                        `postBody${i}`
                                                    );

                                                    if (
                                                        document.querySelector(
                                                            `#postViewBtn${i}`
                                                        ) !== undefined
                                                    ) {
                                                        let postViewBtn =
                                                            document.querySelectorAll(
                                                                `#postViewBtn${i} > span`
                                                            );

                                                        if (
                                                            postViewBtn[0]
                                                                .innerText ===
                                                            "Read less"
                                                        ) {
                                                            postViewBtn[0].innerText =
                                                                "Read more";
                                                        } else {
                                                            postViewBtn[0].innerText =
                                                                "Read less";
                                                        }
                                                        postViewBtn[1].classList.toggle(
                                                            "d-none"
                                                        );
                                                        postViewBtn[2].classList.toggle(
                                                            "d-none"
                                                        );
                                                    }
                                                }
                                            }}
                                        >
                                            <span>Read more</span>
                                            <span>
                                                <CaretDownFilled />
                                            </span>
                                            <span className="d-none">
                                                <CaretUpFilled />
                                            </span>
                                        </a>
                                    </Card.Body>
                                    <hr />
                                    <div className="footerFlex">
                                        <Card.Text className="footerText px-3">
                                            Author: {post.Author}
                                        </Card.Text>
                                        <Card.Text className="footerText  px-3">
                                            Date Published: {post.DatePublished}
                                        </Card.Text>
                                        <Card.Text className="footerText  px-3">
                                            Last Updated: {post.LastUpdated}
                                        </Card.Text>
                                    </div>
                                </Card>
                            );
                        }
                    })
                ) : (
                    <p>No blogs to post</p>
                )}
                {/**
                 * @see setInnerHTML()
                 */}
                <div id="formatHTML" className="d-none"></div>
            </Container>
        </main>
    );
}

export default BlogPage;
