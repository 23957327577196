/**
 * @author Russel Benito
 * @version MAR-15-22
 * @visibleName Employee Login Page
 * @description This page will allow employees to login and manage content on the site. Should the user already be logged in, this page will redirect to the dashboard page.
 */

// React
import { useEffect, useState } from "react";

// Bootstrap
import {
    Form,
    FormGroup,
    FormLabel,
    FormControl,
    //Alert,
} from "react-bootstrap";

//css
import "./EmployeeLogin.css";

// React Router Dom
import { useNavigate } from "react-router-dom";

//Firebase
import { auth, logInWithEmailAndPassword } from "./../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";

// Components
import { SubmitButton } from "../../components/button/Button";

function handleSubmit(e) {
    e.preventDefault();
    logInWithEmailAndPassword(
        e.target.elements["email"].value,
        e.target.elements["password"].value
    );
}

function EmployeeLoginPage() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }

        // if user is already logged in
        if (user) {
            navigate("/dashboard");
        }
    }, [user, loading]);

    return (
        <main className="login page-height">
            <h1 className="text-center mt-5 mb-4 text-uppercase">Login</h1>
            <div className="login_container">
                <Form
                    onSubmit={handleSubmit}
                    className="col-md-8 mx-auto col-lg-4 loginForm"
                >
                    <FormGroup className="mb-3">
                        <FormLabel className="text-uppercase fw-bold fs-6">
                            E-mail
                        </FormLabel>
                        <FormControl
                            type="text"
                            className="login_textbox"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="E-mail address"
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <FormLabel className="text-uppercase fw-bold fs-6">
                            Password
                        </FormLabel>
                        <FormControl
                            type="password"
                            className="password_textbox"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                        />
                    </FormGroup>
                    <FormGroup className="text-center ">
                        <SubmitButton label="Login" title="loginBtn" />
                    </FormGroup>
                </Form>
            </div>
        </main>
    );
}

export default EmployeeLoginPage;
