/**
 * @author Jonathan Kryvenchuk
 * @author Robert Kelly
 * @author Zachary Fedorak
 * @version APR-08-2022
 * @visibleName Main Navigation Bar
 * @description This is the Navigation Bar to be used throughout the whole site. Does not include management pages.
 */

//React Router Bootstrap
import { LinkContainer } from "react-router-bootstrap";

//Bootstrap
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

//Icons
import NavBarLogo from "../icons/NavBarLogo";
import styles from "./MainNavigation.styles.module.css";

function MainNavigation() {
    function setActive(match, location, className) {
        if (match === null) {
            if (document.querySelector("." + className) !== null) {
                document
                    .querySelector("." + className)
                    .classList.remove("active");
            }
        } else {
            if (document.querySelector("." + className) !== null) {
                document.querySelector("." + className).classList.add("active");
            } else {
                return true;
            }
        }

        return false;
    }

    return (
        <header className="bg-white fw-bold sticky-top sticky-lg-top">
            <Navbar
                className="max-container mx-auto"
                expand="lg"
                collapseOnSelect="true"
            >
                <Container
                    fluid
                    className={styles.navText + " " + styles.navBar}
                >
                    {/* Logo & Site Title */}
                    <a href="/" title="Home">
                        <NavBarLogo />
                    </a>

                    <Navbar.Toggle
                        className="border-0 navbar"
                        aria-controls="basic-navbar-nav"
                    />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto navbar">
                            {/* Links to Pages */}
                            <LinkContainer
                                isActive={(match, location) => {
                                    return setActive(
                                        match,
                                        location,
                                        "home-nav-link"
                                    );
                                }}
                                to="/"
                            >
                                <Nav.Link
                                    className={
                                        styles.navText + " home-nav-link"
                                    }
                                >
                                    Home
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer
                                to="/comparison"
                                isActive={(match, location) => {
                                    return setActive(
                                        match,
                                        location,
                                        "comparison-nav-link"
                                    );
                                }}
                            >
                                <Nav.Link
                                    className={
                                        styles.navText + " comparison-nav-link"
                                    }
                                >
                                    Comparison
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer
                                to="/blog"
                                isActive={(match, location) => {
                                    return setActive(
                                        match,
                                        location,
                                        "blog-nav-link"
                                    );
                                }}
                            >
                                <Nav.Link
                                    className={
                                        styles.navText + " blog-nav-link"
                                    }
                                >
                                    Blog
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer
                                className="nav-link"
                                to="/references"
                                isActive={(match, location) => {
                                    return setActive(
                                        match,
                                        location,
                                        "references-nav-link"
                                    );
                                }}
                            >
                                <Nav.Link
                                    className={
                                        styles.navText + " references-nav-link"
                                    }
                                >
                                    References
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer
                                to="/contact"
                                isActive={(match, location) => {
                                    return setActive(
                                        match,
                                        location,
                                        "contact-nav-link"
                                    );
                                }}
                            >
                                <Nav.Link
                                    className={
                                        styles.navText +
                                        " contact-nav-link pe-0"
                                    }
                                >
                                    Contact Us
                                </Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default MainNavigation;
