/**
 * @author Zachary Fedorak
 * @version MAR-29-2022
 * @visibleName References Page
 * @description This page contains references to Manufacturers and other resources given by client.
 */

//Bootstrap
import Container from "react-bootstrap/Container";
//css
import "./References.css";
// component
import ReferenceArrow from "../components/icons/ReferenceArrow";
function ReferencesPage() {
    return (
        <main className="max-container mx-auto">
            <Container fluid>
                <h1 className="refTitle mt-5 text-break">
                    Referenc&shy;e&shy;s
                </h1>
                <div className=" col-12 col-md-8 mx-auto  maincontainer">
                    <div className="flex">
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://boardcoboats.com/blog/"
                        >
                            <p className="linkp">
                                Boardco Boats &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.wakemakers.com/"
                        >
                            <p className="linkp">
                                Wake Makers &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://global.bakesonline.com/"
                        >
                            <p className="linkp">
                                Bakes Online &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://seedealercost.com/products/category-models/index/id/30/productCategorySlug/boats"
                        >
                            <p className="linkp">
                                See Dealer Cost &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://evolutioncovers.com/"
                        >
                            <p className="linkp">
                                Evolution Cover &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://sewlong.com/"
                        >
                            <p className="linkp">
                                Sewlong Covers &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://wake9.com/"
                        >
                            <p className="linkp">
                                Wake 9 &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href=" http://www.guinnpartners.com/boat-testing/"
                        >
                            <p className="linkp">
                                Surf wave comparison &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainertwo col-12"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.cbp.gov/travel/pleasure-boats-private-flyers/pleasure-boat-overview"
                        >
                            <p className="linkp">
                                US Boating Pleasure Boat Reporting &nbsp;
                                <ReferenceArrow />
                            </p>
                        </a>
                    </div>
                </div>

                <h2 className="manTitle text-break">
                    Manufact&shy;u&shy;r&shy;e&shy;r&shy;s
                </h2>
                <div className="col-12 col-md-8 mx-auto maincontainer mb-3">
                    <div className="flex">
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.malibuboats.com/"
                        >
                            <p className="linkp">
                                Malibu &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.axiswake.com/"
                        >
                            <p className="linkp">
                                Axis &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.themalibucrew.com/"
                        >
                            <p className="linkp">
                                Malibu Forum &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://axiswakeboardboats.com/"
                        >
                            <p className="linkp">
                                Axis Forum &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://nautique.com/"
                        >
                            <p className="linkp">
                                Nautique&nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.mastercraft.com/"
                        >
                            <p className="linkp">
                                Mastercraft&nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.planetnautique.com/vb5/"
                        >
                            <p className="linkp">
                                Nautique Forum&nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.mastercraft.com/"
                        >
                            <p className="linkp">
                                Mastercraft Forum&nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.centurionboats.com/"
                        >
                            <p className="linkp">
                                Centurion &nbsp;
                                <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://supremetowboats.com/"
                        >
                            <p className="linkp">
                                Supreme &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="http://www.centurioncrew.com/"
                        >
                            <p className="linkp">
                                Centurion Forum&nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.atxboats.com/"
                        >
                            <p className="linkp">
                                ATX &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://mbsportsusa.com/"
                        >
                            <p className="linkp">
                                MB Sports &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.tige.com/"
                        >
                            <p className="linkp">
                                Tige &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://mbworld.org/forums/"
                        >
                            <p className="linkp">
                                MB Sports Forum &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.tigeowners.com/"
                        >
                            <p className="linkp">
                                Tige Forum &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.moomba.com/"
                        >
                            <p className="linkp">
                                Moomba &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://sangerboats.com/"
                        >
                            <p className="linkp">
                                Sanger &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://forum.moomba.com/"
                        >
                            <p className="linkp">
                                Moomba Forum &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.supraboats.com/"
                        >
                            <p className="linkp">
                                Supra &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://pavati.com/"
                        >
                            <p className="linkp">
                                Pavati &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12 col-lg-6"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://forum.supraboats.com/"
                        >
                            <p className="linkp">
                                Supra Forum&nbsp; <ReferenceArrow />
                            </p>
                        </a>
                        <a
                            className="linkcontainer col-12"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.heydaywakeboats.com/"
                        >
                            <p className="linkp">
                                Heyday &nbsp; <ReferenceArrow />
                            </p>
                        </a>
                    </div>
                </div>
            </Container>
        </main>
    );
}

export default ReferencesPage;
