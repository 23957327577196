/**
 * @author Fred-III-Whiteman
 * @version MAR-1-2022
 * @visiblename Delete document
 * @Description This component is used to delete a firestore document. It takes a documentId and returns a confirmation message
 * @param props.collection - The collection to be used.
 * @param props.docId - The document id to be used.
 * @returns {string} - A confirmation message.
 */

import { db } from "../../firebase/config";

function DeleteDoc(props) {
    let message;

    if (props.docId) {
        const result = db
            .collection(props.collection)
            .doc(props.docId)
            .delete();

        if (result) {
            message = "Successfully deleted from the database. ";
        } else {
            message = "Operation unsuccessful. ";
        }
    } else {
        message = "No Document ID provided.";
    }

    return message;
}

export default DeleteDoc;
