/**
 * @author Jonathan Kryvenchuk
 * @author Omer Ahmed
 * @version MAR-25-2022
 * @description Delta Icon
 */

//React
import React from "react";

//Prop Types
import PropTypes from "prop-types";

function DeltaIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 424.88 424.41"
            width={props.width}
            height={props.height}
            fill={props.color}
            className="m-0 p-0"
        >
            <path
                d="M462.44,456.77H37.56L250,32.36ZM97.26,419.9H384.83L240.72,133.28Z"
                transform="translate(-37.56 -32.36)"
            />
        </svg>
    );
}

DeltaIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

DeltaIcon.defaultProps = {
    color: "black",
    width: "32",
    height: "32",
};

export default DeltaIcon;
