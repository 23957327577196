/**
 * @author Fred-III_Whiteman
 * @author Russel Benito
 * @author Zachary Fedorak
 * @author Afi Ali
 * @version MAR-11-2022
 * @visiblename List all Maunfacturers
 * @Description This page will list all of the boat manufacturers in the database and allow the user to select a particular one to manage, or to add a new one.
 */

// React
import React, { useEffect } from "react";

// Bootstrap
import { Container } from "react-bootstrap";

// React Router
import { useNavigate } from "react-router-dom";

// React table
import { Paginated } from "../../components/table/PaginatedTable";

// Firebase
import { auth } from "../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import DataFetcher from "../../components/dataFetcher/DataFetcher";

//  Button component
import { DefaultButton } from "../../components/button/Button";

function ListManufacturersPage() {
    // Authenticate login - if user isn't valid, redirect to home page
    // eslint-disable-next-line no-unused-vars
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/");
        }
    }, [user, loading]);

    let data = DataFetcher("Manufacturer");

    const routeData = (name, url, visible) => {
        navigate("/manufacturer/manage", {
            state: {
                ManufacturerName: name,
                ManufacturerURL: url,
                Visible: visible,
            },
        });
    };

    const columns = [
        {
            Header: "Name",
            accessor: "key",
        },
        {
            Header: "URL",
            accessor: "ManufacturerURL",
        },
        {
            Header: "Visible",
            accessor: "Visible",
            Cell: ({ value }) => {
                return value ? "yes" : "no";
            },
        },
        {
            Header: "Select",
            accessor: "select",
            Cell: (info) => {
                return (
                    <DefaultButton
                        label="Edit"
                        title="customBtn"
                        handleClick={() => {
                            routeData(
                                info.row.original.key,
                                info.row.original.ManufacturerURL,
                                info.row.original.Visible
                            );
                        }}
                    >
                        Edit
                    </DefaultButton>
                );
            },
        },
    ];

    return (
        <main className="max-container m-auto">
            <Container fluid>
                <div>
                    <h1>All Manufacturers</h1>
                    <div className="d-flex no-wrap justify-content-between my-3">
                        <DefaultButton
                            label="Go back to the dashboard"
                            title="customBtn"
                            handleClick={() => navigate("/dashboard")}
                        />
                        <DefaultButton
                            label="Add a new Manufacturer"
                            title="customBtn"
                            handleClick={() => {
                                routeData("", "", false);
                            }}
                        />
                    </div>
                    {/* Table rendering */}
                    <Paginated data={data} columns={columns} />
                </div>
            </Container>
        </main>
    );
}

export default ListManufacturersPage;
