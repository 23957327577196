/**
 * @author Fred III Whiteman
 * @author Zachary Fedorak
 * @version Apr-06-2022
 * @description inverted Instagram Icon
 */

//React
import React from "react";

// css
import "./Icon.css";

// import
import PropTypes from "prop-types";
function InvertedInstagramIcon(props) {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            fill={props.color}
            className={props.className}
            viewBox="0 0 150 150"
            preserveAspectRatio="xMidYMid meet"
        >
            <g transform="translate(0,150) scale(0.1,-0.1)" stroke="none">
                <path d="M560 1475 c-30 -8 -98 -36 -150 -62 -81 -39 -109 -60 -191 -142 -83 -83 -102 -109 -142 -191 -65 -135 -80 -209 -75 -361 8 -211 68 -349 218 -499 96 -96 164 -140 283 -183 107 -39 292 -49 412 -22 235 52 453 248 541 485 24 65 28 93 32 219 3 115 1 157 -13 210 -64 243 -248 443 -486 528 -65 23 -93 26 -224 29 -110 3 -164 0 -205 -11z m450 -346 c53 -18 98 -55 123 -102 21 -39 22 -56 25 -273 2 -143 -1 -247 -7 -271 -17 -64 -51 -106 -111 -136 l-55 -28 -250 3 -250 3 -47 27 c-36 22 -53 41 -75 85 l-28 58 0 235 0 235 28 58 c37 75 91 108 191 118 101 11 414 2 456 -12z" />
                <path d="M482 1059 c-29 -15 -50 -35 -65 -63 -20 -39 -21 -54 -21 -266 0 -243 2 -257 56 -308 40 -36 81 -42 303 -42 199 0 213 1 255 23 81 40 85 56 85 327 0 217 -2 238 -20 269 -11 19 -33 43 -48 55 -27 20 -42 21 -265 24 -226 2 -239 1 -280 -19z m519 -59 c29 -16 26 -67 -5 -81 -50 -23 -90 36 -50 75 18 19 28 20 55 6z m-148 -82 c23 -13 55 -43 72 -66 27 -40 30 -51 30 -122 0 -71 -3 -82 -30 -121 -100 -142 -322 -113 -380 50 -28 82 -13 151 47 217 69 75 172 91 261 42z" />
                <path d="M704 869 c-18 -5 -46 -25 -62 -44 -57 -64 -51 -147 14 -205 62 -54 172 -37 214 33 45 73 15 172 -63 207 -46 22 -57 22 -103 9z" />
            </g>
        </svg>
    );
}
InvertedInstagramIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
};

InvertedInstagramIcon.defaultProps = {
    color: "#fff",
    width: "55",
    height: "55",
    className: "invertedIconsvg",
};

export default InvertedInstagramIcon;
