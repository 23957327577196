/**
 * @author Fred-III-Whiteman
 * @author Jonathan Kryvenchuk
 * @author Omer Ahmed
 * @author Zachary Fedorak
 * @author Afi Ali
 * @author Russel Benito
 * @version MAR-25-2022
 * @visiblename List Boats Page
 * @description This page will list all of the boats in the database and allow the user to select a single boat to manage, or add a new boat.
 */

// Bootstrap
import Container from "react-bootstrap/Container";

// React
import { useNavigate } from "react-router-dom";
import { useMemo, useEffect } from "react";

// Firebase / Database
import { auth } from "../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";

// Components
import { Paginated } from "../../components/table/PaginatedTable";
import { DefaultButton } from "../../components/button/Button";
import DataFetcher from "../../components/dataFetcher/DataFetcher";

function ListBoatsPage() {
    // Authenticate login - if user isn't valid, redirect to home page
    // eslint-disable-next-line no-unused-vars
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/");
        }
    }, [user, loading]);

    //gets the collection of boats from firebase
    const data = DataFetcher("Boat");

    //#region table columns
    const BoatColumns = useMemo(() => [
        {
            Header: "Manufacturer",
            accessor: "Manufacturer",
        },
        {
            Header: "Model",
            accessor: "Model",
        },
        {
            Header: "Year",
            accessor: "Year",
        },
        {
            Header: "Length",
            accessor: "LengthFeet",
            Cell: (info) => {
                let originalLength = parseFloat(info.row.original.Length);
                let lengthFeet = Math.floor(originalLength);

                let decimalInches = originalLength - Math.floor(originalLength);
                // round decimal value to a precision of 2 (0.00)
                decimalInches =
                    Math.round((decimalInches + Number.EPSILON) * 100) / 100;
                let lengthInches = Math.round(decimalInches * 12);
                return lengthFeet + "' " + lengthInches + '"';
            },
        },
        {
            Header: "Length - decimal",
            accessor: "Length",
        },
        {
            Header: "Dry Weight - lbs",
            accessor: "DryWeight",
        },
        {
            Header: "Max Stock Ballast - lbs",
            accessor: "MaxStockBallast",
        },
        {
            Header: "Weight Capacity - lbs",
            accessor: "WeightCapacity",
        },
        {
            Header: "Total Displacement - D lbs",
            accessor: "TotalDisplacement",
        },
        {
            Header: "D/foot",
            accessor: "Dfoot",
        },
        {
            Header: "Beam - inches",
            accessor: "Beam",
        },
        {
            Header: "Draft - inches",
            accessor: "Draft",
        },
        {
            Header: "Seating Capacity",
            accessor: "SeatingCapacity",
        },
        {
            Header: "MSRP",
            accessor: "MSRP",
        },
        {
            Header: "Visible",
            accessor: "Visible",
            Cell: ({ value }) => {
                return value ? "yes" : "no";
            },
        },
        {
            Header: "Select",
            accessor: "Select",
            Cell: (info) => {
                // eslint-disable-next-line react/prop-types
                return (
                    <DefaultButton
                        label="Edit"
                        title="customBtn"
                        handleClick={() => {
                            routeData(
                                info.row.original.key,
                                info.row.original.Manufacturer,
                                info.row.original.Model,
                                info.row.original.Year,
                                info.row.original.Length,
                                info.row.original.DryWeight,
                                info.row.original.MaxStockBallast,
                                info.row.original.WeightCapacity,
                                info.row.original.TotalDisplacement,
                                info.row.original.Dfoot,
                                info.row.original.Beam,
                                info.row.original.Draft,
                                info.row.original.SeatingCapacity,
                                info.row.original.MSRP,
                                info.row.original.Visible
                            );
                        }}
                    />
                );
            },
        },
    ]);
    //#endregion

    //#region table data
    //pass data through when click edit to the next page
    const routeData = (
        key,
        manufacturer,
        model,
        year,
        length,
        dryWeight,
        maxStockBallast,
        weightCapacity,
        totalDisplacement,
        dFoot,
        beam,
        draft,
        seatingCapacity,
        msrp,
        visible
    ) => {
        navigate("/boats/manage", {
            state: {
                Key: key,
                Manufacturer: manufacturer,
                Model: model,
                Year: year,
                Length: length,
                DryWeight: dryWeight,
                MaxStockBallast: maxStockBallast,
                WeightCapacity: weightCapacity,
                TotalDisplacement: totalDisplacement,
                Dfoot: dFoot,
                Beam: beam,
                Draft: draft,
                SeatingCapacity: seatingCapacity,
                MSRP: msrp,
                Visible: visible,
            },
        });
    };
    // pass empty data when adding new boat
    const routeChange = () => {
        navigate("/boats/manage", {
            state: {
                Key: "",
                Manufacturer: "Select a Manufacturer",
                Model: "",
                Year: null,
                Length: null,
                DryWeight: null,
                MaxStockBallast: null,
                WeightCapacity: null,
                TotalDisplacement: null,
                Dfoot: null,
                Beam: null,
                Draft: null,
                SeatingCapacity: null,
                MSRP: null,
                Visible: false,
            },
        });
    };
    //#endregion

    return (
        <main>
            <Container>
                <div>
                    <h1>All Boats</h1>
                    <div className="d-flex no-wrap justify-content-between my-3">
                        <DefaultButton
                            label="Go back to the dashboard"
                            title="customBtn"
                            handleClick={() => navigate("/dashboard")}
                        />
                        <DefaultButton
                            label="Add new Boat"
                            title="customBtn"
                            handleClick={routeChange}
                        />
                    </div>
                    <Paginated data={data} columns={BoatColumns} />
                </div>
            </Container>
        </main>
    );
}

export default ListBoatsPage;
