/**
 * @author Russel Benito
 * @author Fred III Whiteman
 * @version APR-05-2022
 * @visibleName List Blogs Page
 * @description This page is used for the admin to view all blog posts.
 */

//#region imports
//Bootstrap
import Container from "react-bootstrap/Container";

//React
import React, { useEffect } from "react";

// React Router
import { useNavigate } from "react-router-dom";

// React table
import { Paginated } from "../../components/table/PaginatedTable";

//Firebase
import { auth } from "../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import DataFetcher from "../../components/dataFetcher/DataFetcher";

//  Button component
import { DefaultButton } from "../../components/button/Button";

//  Read more read less
//import ReactReadMoreReadLess from "react-read-more-read-less";

// Markdown parser
import MarkdownIt from "markdown-it";

// CSS
import Styles from "./ListBlogs.module.css";

//#endregion

function ListBlogsPage() {
    // Authentication
    // eslint-disable-next-line no-unused-vars
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const routeData = (
        key,
        position,
        title,
        body,
        author,
        datePublished,
        lastUpdated,
        published
    ) => {
        navigate("/blog/manage", {
            state: {
                Key: key,
                Position: position,
                Title: title,
                Body: body,
                Author: author,
                DatePublished: datePublished,
                LastUpdated: lastUpdated,
                Published: published,
            },
        });
    };

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/");
        }
    }, [user, loading]);

    let data = DataFetcher("Posts");

    const columns = [
        {
            Header: "Position",
            accessor: "Position",
        },
        {
            Header: "Title",
            accessor: "Title",
        },
        {
            Header: "Body",
            accessor: "Body",
            // eslint-disable-next-line react/prop-types
            Cell: ({ value }) => {
                const mdParser = new MarkdownIt();
                return (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: mdParser.render(value),
                        }}
                    />
                );
            },
        },
        {
            Header: "Author",
            accessor: "Author",
        },
        {
            Header: "Date Published",
            accessor: "DatePublished",
        },
        {
            Header: "Last Updated",
            accessor: "LastUpdated",
        },
        {
            Header: "Published",
            accessor: "Published",
            Cell: ({ value }) => {
                return value ? "yes" : "no";
            },
        },
        // For selecting a blog post to manage
        {
            Header: "Select",
            accessor: "Select",
            Cell: (info) => {
                return (
                    <DefaultButton
                        label="Edit"
                        title="customBtn"
                        handleClick={() => {
                            routeData(
                                info.row.original.key,
                                info.row.original.Position,
                                info.row.original.Title,
                                info.row.original.Body,
                                info.row.original.Author,
                                info.row.original.DatePublished,
                                info.row.original.LastUpdated,
                                info.row.original.Published
                            );
                        }}
                    >
                        Edit
                    </DefaultButton>
                );
            },
        },
    ];

    return (
        <main className="max-container m-auto">
            <Container fluid>
                <div className={Styles.listBlog}>
                    <h1>All Blog Posts</h1>
                    <div className="d-flex no-wrap justify-content-between my-3">
                        <DefaultButton
                            label="Go back to the dashboard"
                            title="customBtn"
                            handleClick={() => navigate("/dashboard")}
                        />
                        <DefaultButton
                            label="Add a new blog post"
                            title="customBtn"
                            handleClick={() => {
                                routeData(
                                    "",
                                    data.length + 1,
                                    "",
                                    "",
                                    "",
                                    "",
                                    "",
                                    false
                                );
                            }}
                        />
                    </div>
                    {/* Table rendering */}
                    <Paginated data={data} columns={columns} />
                </div>
            </Container>
        </main>
    );
}

export default ListBlogsPage;
