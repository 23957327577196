/**
 * @author Jonathan Kryvenchuk
 * @version JAN-28-2022
 * @description Create context object to be used in index.js
 * @see https://youtu.be/ZLcU-bwxzXQ?t=1040
 */

// React
import { createContext } from "react";

export const FirebaseContext = createContext(null);
