/**
 * @version JAN-28-2022
 * @description This is entry point for react to show our app
 */

// React
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// CSS
import "./index.css";

//Firebase
import { FirebaseContext } from "./context/FirebaseContext";
import { firebase } from "./firebase/config";

ReactDOM.render(
    <BrowserRouter>
        {/* Wrap <App /> in Firebase Context */}
        <FirebaseContext.Provider value={{ firebase }}>
            <App />
        </FirebaseContext.Provider>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
