/**
 * @author Afi ALI
 * @version MAR-23-2022
 * @description This component is used to fetch data using the Firestore Database
 * @param collectionName - The name of the Firestore collection.
 * @see https://youtu.be/ckkeZ_f3b-M?list=PL8CiOz301vHt_Bo8ZskrSlXEoPpxXyG5Y
 */

// React
import { useEffect, useState } from "react";

//Firebase
import { db } from "../../firebase/config";
function DataFetcher(collectionName) {
    const [loading, setLoading] = useState(true);
    const [collection, setCollection] = useState([]);

    //  Return Posts in order by "Count"
    if (collectionName == "Posts") {
        useEffect(() => {
            const getCollectionFromFirebase = [];
            const list = db
                .collection(collectionName)
                .orderBy("Position")
                .onSnapshot((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        getCollectionFromFirebase.push({
                            ...doc.data(),
                            key: doc.id,
                        });
                    });
                    setCollection(getCollectionFromFirebase);
                    if (loading === false) {
                        setLoading(false);
                    }
                });
            return () => list;
        }, [loading]);
    } else {
        useEffect(() => {
            const getCollectionFromFirebase = [];
            const list = db
                .collection(collectionName)
                .onSnapshot((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        getCollectionFromFirebase.push({
                            ...doc.data(),
                            key: doc.id,
                        });
                    });
                    setCollection(getCollectionFromFirebase);
                    if (loading === false) {
                        setLoading(false);
                    }
                });
            return () => list;
        }, [loading]);
    }
    return collection;
}

export default DataFetcher;
