/* eslint-disable react/prop-types */
/**
 * @author Zachary Fedorak
 * @version MAR-08-2022
 * @visibleName Default Button Component
 * @description This component will be used for all basic buttons with type submit for all the pages
 */

// css
import "./Button.css";

function DefaultButton({ label, title, handleClick }) {
    return (
        <button className={title} onClick={handleClick}>
            {label}
        </button>
    );
}

function SubmitButton({ label, title, handleClick }) {
    return (
        <button type="submit" className={title} onClick={handleClick}>
            {label}
        </button>
    );
}

export { DefaultButton, SubmitButton };
