/**
 * @author Fred-III-Whiteman
 * @version MAR-1-2022
 * @visiblename Add or Update documents
 * @Description This component is used to create or update a firestore document. It takes a map structured like the comment at the bottom and returns a confirmation message.
 * @param props.collection - The collection to be used.
 * @param props.docId - The document id to be used.
 * @param props.info - The information to be added or updated.
 * @returns {string} - A confirmation message.
 */

//FireStore;
import { db } from "../../firebase/config";

function AddUpdate(props) {
    let message = {
        success: "",
        failure: "",
    };
    let doc;
    // determining if it should use .set or .add (.add generates a docId and .set takes a user defined docId)
    if (!props.docId) {
        doc = db.collection(props.collection).add(props.info);
    } else {
        doc = db
            .collection(props.collection)
            .doc(props.docId)
            .set(props.info, { merge: true });
    }
    if (doc) {
        message.success = "Document created or updated.";
    } else {
        message.failure = "Document not created or updated.";
    }
    return message;
}

export default AddUpdate;

// example data for a Manufacturer
// const data = {
//     collection: "Manufacturer",
//     docId: document.querySelector(".manufacturerName").value,
//     info: {
//         ManufacturerURL: document.querySelector(".manufacturerURL").value,
//         Visible: document.querySelector(".invisibleCheck").firstChild.checked,
//     },
// };
