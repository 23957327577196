/* eslint-disable prettier/prettier */
/**
 * @author Afi ALi
 * @author Zachary Fedorak
 * @author Fred III Whiteman
 * @version MAR-25-2022
 * @visibleName Boats Management Page
 * @description This page is to manage the boats, whether the admin would like to add and delete boats or to update any of the specs.
 */

// #region Imports
// Bootstrap
import {
    Form,
    FormGroup,
    FormLabel,
    Alert,
    Stack,
    Container,
    FormControl,
} from "react-bootstrap";

// React
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// firebase / database
import { auth } from "../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import DataFetcher from "../../components/dataFetcher/DataFetcher";
import DeleteDoc from "../../components/crudFunctions/DeleteDoc";
import AddUpdate from "../../components/crudFunctions/AddUpdate";

// Button components
import { DefaultButton, SubmitButton } from "../../components/button/Button";

// css
import styles from "./ManagementForm.module.css";

// #endregion

// #region Configurable Constants
// Valid Model string length
const MIN_MODEL_LENGTH = 2;
const MAX_MODEL_LENGTH = 200;

// Valid Year range
const MIN_YEAR = 1000;
const MAX_YEAR = 9999;

// Valid Boat Length - feet
const MIN_LENGTH_FOOT = 0;
const MAX_LENGTH_FOOT = 99;

// Valid Boat Length - inches
const MIN_LENGTH_INCHES = 0;
const MAX_LENGTH_INCHES = 11;

// Valid Dry Weight range
const MIN_DRY_WEIGHT = 0;
const MAX_DRY_WEIGHT = 9999;

// Valid Stock Ballast range
const MIN_STOCK_BALLAST = 0;
const MAX_STOCK_BALLAST = 9999;

// Valid Weight Capacity range
const MIN_WEIGHT_CAPACITY = 0;
const MAX_WEIGHT_CAPACITY = 9999;

// Valid Total Displacement range
const MIN_TOTAL_DISPLACEMENT = 0;
const MAX_TOTAL_DISPLACEMENT = 99999;

// Valid D/Foot range
const MIN_D_FOOT = 0;
const MAX_D_FOOT = 999;

// Valid Beam range
const MIN_BEAM = 0;
const MAX_BEAM = 999;

// Valid Draft range
const MIN_DRAFT = 0;
const MAX_DRAFT = 999;
const DRAFT_PRECISION = 2;
const DRAFT_STEP = 0.01;

// Valid Seating Capacity range
const MIN_SEATING = 0;
const MAX_SEATING = 50;

// Valid MSRP range
const MIN_MSRP = 0;
const MAX_MSRP = 999999;

// #endregion

// #region Helpers
function saveBoatData(docKey) {
    // calculate the length in decimal from feet and inches
    let lengthDecimal;
    let lengthFeet = document.querySelector("#LengthFeetId").value;
    let lengthInches = document.querySelector("#LengthInchesId").value;
    // round a decimal to a precision of two
    let decimalInches = Math.round((lengthInches/12 + Number.EPSILON) * 100) / 100;
    
    lengthDecimal = parseFloat(lengthFeet) + parseFloat(decimalInches);

    // if the input is not required use a conditional to check if the input is empty
    const data = {
        collection: "Boat",
        docId: docKey,
        info: {
            Manufacturer: document.querySelector("#ManufacturerId").value,
            Model: document.querySelector("#ModelId").value,
            Year: parseInt(document.querySelector("#YearId").value),
            Length: lengthDecimal,
            DryWeight: parseInt(document.querySelector("#DryWeightId").value),
            MaxStockBallast: parseInt(document.querySelector("#MaxStockId").value),
            WeightCapacity: parseInt(document.querySelector("#WeightCapacityId").value),
            TotalDisplacement: parseInt(document.querySelector("#TotalDisplacementId")
                .value),
            Dfoot: parseInt(document.querySelector("#DFootId").value),
            Beam: parseInt(document.querySelector("#BeamId").value),
            Draft: document.querySelector("#DraftId").value
                ? parseInt(document.querySelector("#DraftId").value)
                : null,
            SeatingCapacity: parseInt(document.querySelector("#SeatingCapacityId").value),
            MSRP: document.querySelector('#MSRPId').value
                ? parseInt(document.querySelector('#MSRPId').value)
                : null,
            Visible: document.querySelector("#VisibleId").checked,
        },
    };

    const message = AddUpdate(data);
    return message;
}

function clearFields() {
    document.querySelector("#ManufacturerId").value = "";
    document.querySelector("#ModelId").value = "";
    document.querySelector("#YearId").value = null;
    document.querySelector("#LengthFeetId").value = null;
    document.querySelector("#LengthInchesId").value = null;
    document.querySelector("#DryWeightId").value = null;
    document.querySelector("#MaxStockId").value = null;
    document.querySelector("#WeightCapacityId").value = null;
    document.querySelector("#TotalDisplacementId").value = null;
    document.querySelector("#DFootId").value = null;
    document.querySelector("#BeamId").value = null;
    document.querySelector("#DraftId").value = null;
    document.querySelector("#SeatingCapacityId").value = null;
    document.querySelector("#MSRPId").value = null;
    document.querySelector("#VisibleId").checked = false;

    // clear the alert
    document.querySelector(".successMessage").innerText = "";
    document.querySelector(".errorMessage").innerText = "";
    document.querySelector(".errorList").innerHTML = "";
}
// #endregion

function BoatManagementPage() {
    // #region declarations and useEffect
    // Authenticate login - if user isn't valid, redirect to home page
    // eslint-disable-next-line no-unused-vars
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/");
        }
    }, [user, loading]);

    const location = useLocation();

    const manufacturersCollection = DataFetcher("Manufacturer");
    const [isHidden, setIsHidden] = useState(true);
    const [shouldHide, setHide] = useState(true);
    const [validated, setValidated] = useState(false);

    let returnMessage = {
        success: "",
        failure: "",
    };
    let errorMessage;
    let validationMessages = {
        manufacturerMessage: "",
        modelMessage: "",
        yearMessage: "",
        lengthFeetMessage: "",
        lengthInchesMessage: "",
        dryWeightMessage: "",
        stockBallastMessage: "",
        weightCapacityMessage: "",
        TotalDisplacementMessage: "",
        dFootMessage: "",
        beamMessage: "",
        draftMessage: "",
        seatingCapacityMessage: "",
        msrpMessage: "",
    };
    // #endregion

    // #region Validation
    // The form validation is done in individual functions to allow for the use of the native HTML5 validation.
    function validateBoat() {
        validateManufacturer();
        validateModel();
        validateYear();
        validateLengthFeet();
        validateLengthInches();
        validateDryWeight();
        validateMaxStockBallast();
        validateWeightCapacity();
        validateTotalDisplacement();
        validateDFoot();
        validateBeam();
        validateDraft();
        validateSeatingCapacity();
        validateMSRP();

        return validationMessages;
    }

    function validateManufacturer() {
        const input = document.getElementById("ManufacturerId");
        const validityState = input.validity;

        const requiredMessage = "Manufacturer is required.";
        const validSelection = "Please select a Manufacturer.";

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.manufacturerMessage = requiredMessage;
        } else if (input == "..." || input == "") {
            input.setCustomValidity(validSelection);
            validationMessages.manufacturerMessage = validSelection;
        } else {
            input.setCustomValidity("");
            validationMessages.manufacturerMessage = "";
        }

        input.reportValidity();
    }

    function validateModel() {
        const input = document.getElementById("ModelId");
        const validityState = input.validity;

        const requiredMessage = "Model is required.";
        const tooShortMessage = `Model must be at least ${MIN_MODEL_LENGTH} characters long.`;
        const tooLongMessage = `Model must be less than ${MAX_MODEL_LENGTH} characters long.`;

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.modelMessage = requiredMessage;
        } else if (validityState.tooShort) {
            input.setCustomValidity(tooShortMessage);
            validationMessages.modelMessage = tooShortMessage;
        } else if (validityState.tooLong) {
            input.setCustomValidity(tooLongMessage);
            validationMessages.modelMessage = tooLongMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.modelMessage = "";
        }

        input.reportValidity();
    }

    function validateYear() {
        const input = document.getElementById("YearId");
        const validityState = input.validity;

        const requiredMessage = "Year is required.";
        const rangeUnderflowMessage = `Year must be greater than ${MIN_YEAR}`;
        const rangeOverflowMessage = `Year must be less than ${MAX_YEAR}`;
        const typeMismatchMessage = "Year must be a number.";

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.yearMessage = requiredMessage;
        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.yearMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.yearMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.yearMessage = typeMismatchMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.yearMessage = "";
        }

        input.reportValidity();
    }

    function validateLengthFeet() {
        const input = document.getElementById("LengthFeetId");
        const validityState = input.validity;

        const requiredMessage = "Length in feet is required.";
        const rangeUnderflowMessage = `Length in feet must be greater than ${MIN_LENGTH_FOOT}`;
        const rangeOverflowMessage = `Length in feet must be less than ${MAX_LENGTH_FOOT}`;
        const typeMismatchMessage = "Length in feet must be a number.";

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.lengthFeetMessage = requiredMessage;
        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.lengthFeetMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.lengthFeetMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.lengthFeetMessage = typeMismatchMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.lengthFeetMessage = "";
        }
        
        input.reportValidity();
    }

    function validateLengthInches() {
        const input = document.getElementById("LengthInchesId");
        const validityState = input.validity;

        const requiredMessage = "Length in inches is required.";
        const rangeUnderflowMessage = `Length in inches must be greater than ${MIN_LENGTH_INCHES}`;
        const rangeOverflowMessage = `Length in inches must be less than ${MAX_LENGTH_INCHES}`;
        const typeMismatchMessage = "Length in inches must be a number.";

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.lengthInchesMessage = requiredMessage;
        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.lengthInchesMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.lengthInchesMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.lengthInchesMessage = typeMismatchMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.lengthInchesMessage = "";
        }
        
        input.reportValidity();
    }

    function validateDryWeight() {
        const input = document.getElementById("DryWeightId");
        const validityState = input.validity;

        const requiredMessage = "Dry weight is required.";
        const rangeUnderflowMessage = `Dry weight must be greater than ${MIN_DRY_WEIGHT}`;
        const rangeOverflowMessage = `Dry weight must be less than ${MAX_DRY_WEIGHT}`;
        const typeMismatchMessage = "Dry weight must be a number.";

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.dryWeightMessage = requiredMessage;
        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.dryWeightMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.dryWeightMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.dryWeightMessage = typeMismatchMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.dryWeightMessage = "";
        }
        
        input.reportValidity();
    }

    function validateMaxStockBallast() {
        const input = document.getElementById("MaxStockId");
        const validityState = input.validity;

        const requiredMessage = "Max stock Ballast is required.";
        const rangeUnderflowMessage = `Max stock Ballast must be greater than ${MIN_STOCK_BALLAST}`;
        const rangeOverflowMessage = `Max stock Ballast must be less than ${MAX_STOCK_BALLAST}`;
        const typeMismatchMessage = "Max stock Ballast must be a number.";

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.stockBallastMessage = requiredMessage;
        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.stockBallastMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.stockBallastMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.stockBallastMessage = typeMismatchMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.stockBallastMessage = "";
        }
        
        input.reportValidity();
    }

    function validateWeightCapacity() {
        const input = document.getElementById("WeightCapacityId");
        const validityState = input.validity;

        const requiredMessage = "Weight capacity is required.";
        const rangeUnderflowMessage = `Weight capacity must be greater than ${MIN_WEIGHT_CAPACITY}`;
        const rangeOverflowMessage = `Weight capacity must be less than ${MAX_WEIGHT_CAPACITY}`;
        const typeMismatchMessage = "Weight capacity must be a number.";

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.weightCapacityMessage = requiredMessage;
        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.weightCapacityMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.weightCapacityMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.weightCapacityMessage = typeMismatchMessage;
        } else {
            input.setCustomValidity('');
            validationMessages.weightCapacityMessage = "";
        }
        
        input.reportValidity();
    }

    function validateTotalDisplacement() {
        const input = document.getElementById("TotalDisplacementId");
        const validityState = input.validity;

        const requiredMessage = "Total displacement is required.";
        const rangeUnderflowMessage = `Total displacement must be greater than ${MIN_TOTAL_DISPLACEMENT}`;
        const rangeOverflowMessage = `Total displacement must be less than ${MAX_TOTAL_DISPLACEMENT}`;
        const typeMismatchMessage = "Total displacement must be a number.";

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.TotalDisplacementMessage = requiredMessage;
        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.TotalDisplacementMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.TotalDisplacementMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.TotalDisplacementMessage = typeMismatchMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.TotalDisplacementMessage = "";
        }
        
        input.reportValidity();
    }

    function validateDFoot() {
        const input = document.getElementById("DFootId");
        const validityState = input.validity;

        const requiredMessage = "D foot is required.";
        const rangeUnderflowMessage = `D foot must be greater than ${MIN_D_FOOT}`;
        const rangeOverflowMessage = `D foot must be less than ${MAX_D_FOOT}`;
        const typeMismatchMessage = "D foot must be a number.";

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.dFootMessage = requiredMessage;
        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.dFootMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.dFootMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.dFootMessage = typeMismatchMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.dFootMessage = "";
        }
        
        input.reportValidity();
    }

    function validateBeam() {
        const input = document.getElementById("BeamId");
        const validityState = input.validity;

        const requiredMessage = "Beam is required.";
        const rangeUnderflowMessage = `Beam must be greater than ${MIN_BEAM}`;
        const rangeOverflowMessage = `Beam must be less than ${MAX_BEAM}`;
        const typeMismatchMessage = "Beam must be a number.";

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.beamMessage = requiredMessage;
        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.beamMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.beamMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.beamMessage = typeMismatchMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.beamMessage = "";
        }
        
        input.reportValidity();
    }

    function validateDraft() {
        const input = document.getElementById("DraftId");
        const validityState = input.validity;

        const rangeUnderflowMessage = `Draft must be greater than ${MIN_DRAFT}`;
        const rangeOverflowMessage = `Draft must be less than ${MAX_DRAFT}`;
        const typeMismatchMessage = "Draft must be a number.";

        if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.draftMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.draftMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.draftMessage = typeMismatchMessage
        } else {
            input.setCustomValidity("");
            validationMessages.draftMessage = "";
        }

        input.reportValidity();
    }

    function validateSeatingCapacity() {
        const input = document.getElementById("SeatingCapacityId");
        const validityState = input.validity;

        const requiredMessage = "Seating capacity is required.";
        const rangeUnderflowMessage = `Seating capacity must be greater than ${MIN_SEATING}`;
        const rangeOverflowMessage = `Seating capacity must be less than ${MAX_SEATING}`;
        const typeMismatchMessage = "Seating capacity must be a number.";

        if (validityState.valueMissing) {
            input.setCustomValidity(requiredMessage);
            validationMessages.seatingCapacityMessage = requiredMessage;
        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.seatingCapacityMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.seatingCapacityMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.seatingCapacityMessage = typeMismatchMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.seatingCapacityMessage = "";
        }

        input.reportValidity();
    }

    function validateMSRP() {
        const input = document.getElementById("MSRPId");
        const validityState = input.validity;

        const rangeUnderflowMessage = `MSRP must be greater than ${MIN_MSRP}`;
        const rangeOverflowMessage = `MSRP must be less than ${MAX_MSRP}`;
        const typeMismatchMessage = "MSRP must be a number.";

        if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.msrpMessage = rangeUnderflowMessage;
        } else if (validityState.rangeOverflow) {
            input.setCustomValidity(rangeOverflowMessage);
            validationMessages.msrpMessage = rangeOverflowMessage;
        } else if (validityState.typeMismatch) {
            input.setCustomValidity(typeMismatchMessage);
            validationMessages.msrpMessage = typeMismatchMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.msrpMessage = "";
        }
        
        input.reportValidity();
    }

    // #endregion

    // #region Submit Events
    const onAddUpdate = async (event) => {
        event.preventDefault();

        // declarations
        let errorHeading = document.querySelector(".errorMessage");
        let errorList = document.querySelector(".errorList");
        
        let docKey;

        //confirmation made
        const form = event.currentTarget;
        const result = await confirm(
            "Are you sure you want to save/add this boat."
        );

        //clear old errors
        errorHeading.innerText = "";
        errorList.innerHTML = "";
        setHide(true);
        setIsHidden(true);

        if (result) {
            // validate form inputs
            validationMessages = validateBoat();
            if (form.checkValidity() === false) {
                event.stopPropagation();
            }

            //shows validation
            setValidated(true);

            // get the doc id here as it is not available in the save function
            if (location.state) {
                docKey = location.state.Key;
            } else {
                docKey = "";
            }

            // check that there are no error messages
            if (Object.values(validationMessages).every(x => x === "" || x === null)) {
                returnMessage = saveBoatData(docKey);
            } else {
                errorHeading.innerText = "Input not valid: ";
                setHide(false);
                // display error messages
                Object.values(validationMessages).map((errorMessage) => {
                    if (errorMessage != "") {
                        let li = document.createElement("li");
                        errorList.append(li);
                        li.innerText = errorMessage;
                    }
                });
            }
        }

        if (returnMessage.success != "") {
            setIsHidden(false);
            document.querySelector(".successMessage").innerText = returnMessage.success;
        } else if (returnMessage.failure != "") {
            setHide(false);
            document.querySelector(".errorMessage").innerText = returnMessage.failure;
        }
    };

    const onDelete = async (event) => {
        event.preventDefault();

        const confirmation = await confirm(
            "Are you sure you want to delete this boat?"
        );

        if (confirmation) {
            if (location.state.Key) {
                // Info to pass
                const data = {
                    collection: "Boat",
                    docId: location.state.Key,
                };

                returnMessage = DeleteDoc(data);
                setValidated(false);
                clearFields();
            } else {
                errorMessage = "No boat selected.";
            }
        }

        if (returnMessage.success != "") {
            setIsHidden(false);
            document.querySelector(".successMessage").innerText = returnMessage;
        } else if (errorMessage != "") {
            setHide(false);
            document.querySelector(".errorMessage").innerText = errorMessage;
        } else if (returnMessage.failure != "") {
            setHide(false);
            document.querySelector(".errorMessage").innerText = returnMessage;
        }
    };

    const onClear = (event) => {
        event.preventDefault();
        setValidated(false);
        setHide(true);
        setIsHidden(true);

        
        clearFields();
    }
    // #endregion

    return (
        <main className="max-container m-auto col-lg-6">
            <Container fluid>
                <h1>Manage Boats</h1>
                <div className="d-flex justify-content-center">
                    <DefaultButton
                        label="Go back to boat list"
                        title="customBtn"
                        handleClick={() => navigate("/boats")}
                    />
                </div>
                <div className="col-md-11 col-lg-12 mx-auto">
                    <Alert
                        className="successMessage my-3"
                        variant="success"
                        hidden={isHidden}
                    ></Alert>
                    <Alert
                        className="my-3"
                        variant="danger"
                        hidden={shouldHide}
                    >
                        <Alert.Heading className="errorMessage"></Alert.Heading>
                        <ul className="errorList"></ul>
                    </Alert>
                    <Form
                        className={styles.form + " my-3"}
                        noValidate
                        validated={validated}
                        onSubmit={onAddUpdate}
                    >
                        {/* Boat Form*/}
                        <FormGroup>
                            <div className="row">
                                <div className="col-md-6">
                                    {/* Manufacturer name Select */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>Manufacturer</FormLabel>
                                        <Form.Select
                                            id="ManufacturerId"
                                            required
                                        >
                                            <option
                                                key="0"
                                                defaultValue={
                                                    location.state.Manufacturer
                                                }
                                                label={
                                                    location.state.Manufacturer
                                                }
                                            >
                                                {location.state.Manufacturer}
                                            </option>
                                            {/* Both eslint and prettier/prettier hate this. can't say I blame them */}
                                            {manufacturersCollection.length >
                                                0 ? (manufacturersCollection.filter((manufacturer) => {
                                                    return manufacturer.Visible;
                                                })).map((manufacturer) => {
                                                    return (
                                                        <option
                                                            key={manufacturer.key}
                                                            label={manufacturer.key}
                                                        >
                                                            {manufacturer.key}
                                                        </option>
                                                    );
                                                }) : ( <option>No Manufacturers available</option> )
                                            }
                                        </Form.Select>
                                    </FormGroup>
                                    {/* Boat model input */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>Model</FormLabel>
                                        <FormControl
                                            type="text"
                                            id="ModelId"
                                            defaultValue={location.state.Model}
                                            required
                                            minLength={MIN_MODEL_LENGTH}
                                            maxLength={MAX_MODEL_LENGTH}
                                        />
                                    </FormGroup>
                                    {/* Year of manufacture input */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>Year</FormLabel>
                                        <FormControl
                                            type="number"
                                            id="YearId"
                                            defaultValue={
                                                location.state
                                                    ? location.state.Year
                                                    : null
                                            }
                                            required
                                            min={MIN_YEAR}
                                            max={MAX_YEAR}
                                        />
                                    </FormGroup>
                                    {/* Length (feet) of boat input */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>Length - Feet</FormLabel>
                                        <FormControl
                                            type="number"
                                            id="LengthFeetId"
                                            defaultValue={Math.floor(location.state.Length)}
                                            required
                                            min={MIN_LENGTH_FOOT}
                                            max={MAX_LENGTH_FOOT}
                                        />
                                    </FormGroup>
                                    {/* Length (inches) of boat input */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>Length - Inches</FormLabel>
                                        <FormControl
                                            type="number"
                                            id="LengthInchesId"
                                            defaultValue={
                                                Math.round((Math.round(((location.state.Length - Math.floor(location.state.Length)) + Number.EPSILON) * 100) / 100) * 12)
                                            }
                                            required
                                            min={MIN_LENGTH_INCHES}
                                            max={MAX_LENGTH_INCHES}
                                        />
                                    </FormGroup>
                                    {/* Dry weight of boat input */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>Dry Weight</FormLabel>
                                        <FormControl
                                            type="number"
                                            id="DryWeightId"
                                            defaultValue={location.state.DryWeight}
                                            required
                                            min={MIN_DRY_WEIGHT}
                                            max={MAX_DRY_WEIGHT}
                                        />
                                    </FormGroup>
                                    {/* Max Stock Ballast input */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>Max Stock Ballast</FormLabel>
                                        <FormControl
                                            type="number"
                                            id="MaxStockId"
                                            defaultValue={location.state.MaxStockBallast}
                                            required
                                            min={MIN_STOCK_BALLAST}
                                            max={MAX_STOCK_BALLAST}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-md-6">
                                    {/* Weight Capacity input */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>Weight Capacity</FormLabel>
                                        <FormControl
                                            type="number"
                                            id="WeightCapacityId"
                                            defaultValue={location.state.WeightCapacity}
                                            required
                                            min={MIN_WEIGHT_CAPACITY}
                                            max={MAX_WEIGHT_CAPACITY}
                                        />
                                    </FormGroup>
                                    {/* Total displacement input (amount of water the boat will displace) */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>
                                            Total Displacement
                                        </FormLabel>
                                        <FormControl
                                            type="number"
                                            id="TotalDisplacementId"
                                            defaultValue={location.state.TotalDisplacement}
                                            required
                                            min={MIN_TOTAL_DISPLACEMENT}
                                            max={MAX_TOTAL_DISPLACEMENT}
                                        />
                                    </FormGroup>
                                    {/* Displacement per foot input */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>
                                            Displacement per foot
                                        </FormLabel>
                                        <FormControl
                                            type="number"
                                            id="DFootId"
                                            defaultValue={location.state.Dfoot}
                                            required
                                            min={MIN_D_FOOT}
                                            max={MAX_D_FOOT}
                                        />
                                    </FormGroup>
                                    {/* Beam input (Width at the widest point) */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>Beam</FormLabel>
                                        <FormControl
                                            type="number"
                                            id="BeamId"
                                            defaultValue={location.state.Beam}
                                            required
                                            min={MIN_BEAM}
                                            max={MAX_BEAM}
                                        />
                                    </FormGroup>
                                    {/* Draft input (vertical distance between waterline and the keel (very bottom of the boat))*/}
                                    <FormGroup className="mt-3">
                                        <FormLabel>Draft</FormLabel>
                                        <FormControl
                                            type="number"
                                            id="DraftId"
                                            defaultValue={location.state.Draft}
                                            precision={DRAFT_PRECISION}
                                            step={DRAFT_STEP}
                                            min={MIN_DRAFT}
                                            max={MAX_DRAFT}
                                        />
                                    </FormGroup>
                                    {/* Boat seating capacity input */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>Seating Capacity</FormLabel>
                                        <FormControl
                                            type="number"
                                            id="SeatingCapacityId"
                                            defaultValue={location.state.SeatingCapacity}
                                            required
                                            min={MIN_SEATING}
                                            max={MAX_SEATING}
                                        />
                                    </FormGroup>
                                    {/* Boat MSRP input */}
                                    <FormGroup className="mt-3">
                                        <FormLabel>MSRP</FormLabel>
                                        <FormControl
                                            type="number"
                                            id="MSRPId"
                                            defaultValue={location.state.MSRP}
                                            min={MIN_MSRP}
                                            max={MAX_MSRP}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {/* Visible section */}
                                    <FormGroup className="mt-3 d-flex gap-3">
                                        <FormLabel>Visible: </FormLabel>
                                        <Form.Check
                                            inline
                                            type="switch"
                                            id="VisibleId"
                                            className="invisibleCheck"
                                            defaultChecked={location.state.Visible}
                                        ></Form.Check>
                                    </FormGroup>
                                </div>
                            </div>
                        </FormGroup>
                        {/* Buttons */}
                        <Stack
                            direction="horizontal"
                            gap={3}
                            className="buttonGroup mt-3"
                        >
                            <SubmitButton label="Save Boat" title="customBtn" />
                            <DefaultButton
                                label="Delete"
                                title="deleteBtn"
                                handleClick={onDelete}
                            />

                            <DefaultButton
                                label="Clear"
                                title="clearBtn"
                                handleClick={onClear}
                            />
                        </Stack>
                    </Form>
                </div>
            </Container>
        </main>
    );
}

export default BoatManagementPage;
