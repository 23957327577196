/**
 * @author Fred III Whiteman
 * @author Zachary Fedorak
 * @version Apr-06-2022
 * @description inverted envelope Icon
 */

//React
import React from "react";

// css
import "./Icon.css";
//import
import PropTypes from "prop-types";
function InvertedEnvelopeIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
            fill={props.color}
            className={props.className}
            viewBox="0 0 150 150"
        >
            <g transform="translate(0,150) scale(0.1,-0.1)" stroke="none">
                <path d="M605 1476 c-83 -16 -174 -51 -252 -97 -73 -44 -198 -169 -242 -242 -227 -383 -90 -859 304 -1053 122 -60 196 -77 330 -77 209 0 370 67 520 218 104 103 162 202 196 332 28 108 28 270 0 376 -67 253 -277 464 -530 532 -80 22 -242 27 -326 11z m545 -440 c-14 -13 -110 -89 -213 -170 -163 -126 -191 -145 -208 -135 -29 15 -403 310 -407 320 -2 5 184 9 424 9 l429 0 -25 -24z m60 -297 c0 -203 -2 -230 -15 -225 -9 3 -72 52 -141 108 -83 68 -124 108 -122 120 3 16 254 228 271 228 4 0 7 -104 7 -231z m-775 116 c78 -63 124 -106 122 -116 -2 -16 -253 -229 -269 -229 -5 0 -8 102 -8 226 0 198 2 225 15 222 9 -2 72 -48 140 -103z m244 -210 c21 -19 48 -35 61 -35 12 0 40 16 63 35 22 19 45 35 50 35 12 0 316 -242 316 -252 1 -5 -191 -8 -425 -8 -246 0 -424 4 -422 9 4 12 299 251 309 251 5 0 26 -16 48 -35z" />
            </g>
        </svg>
    );
}
InvertedEnvelopeIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
};

InvertedEnvelopeIcon.defaultProps = {
    color: "#fff",
    width: "55",
    height: "55",
    className: "invertedIconsvg",
};

export default InvertedEnvelopeIcon;
