/**
 * @author Russel Benito
 * @author Fred-III-Whiteman
 * @version MAR-25-2022
 * @visiblename Manufacturer Management Page
 * @Description This page is used to edit/create/delete Manufacturers of the boats in the database.
 */
// #region imports
// Bootstrap
import {
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Container,
    Alert,
    Stack,
} from "react-bootstrap";

// React
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Firebase / database
import { auth } from "../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import AddUpdate from "../../components/crudFunctions/AddUpdate";
import DeleteDoc from "../../components/crudFunctions/DeleteDoc";

// Button
import { DefaultButton, SubmitButton } from "../../components/button/Button";

// CSS
import styles from "./ManagementForm.module.css";

// #endregion

// #region configurable constants
// Valid Manufacturer name length
const MIN_MANUFACTURE_NAME_LENGTH = 3;
const MAX_MANUFACTURER_NAME_LENGTH = 50;

// Valid Manufacutrer URL
const MIN_MANUFACTURER_URL_LENGTH = 5;
const MAX_MANUFACTURER_URL_LENGTH = 50;

// from https://docs.microsoft.com/en-us/previous-versions/msp-n-p/ff650303(v=pandp.10)
// eslint-disable-next-line no-useless-escape, prettier/prettier
const VALID_MANUFACTURER_URL_REGEX = "^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$";
// #endregion

// #region Helper Functions
function saveManufacturerData() {
    // info to pass
    const data = {
        collection: "Manufacturer",
        docId: document.querySelector("#manufacturerName").value,
        info: {
            ManufacturerURL: document.querySelector("#manufacturerURL").value,
            Visible:
                document.querySelector(".invisibleCheck").firstChild.checked,
        },
    };

    // add data
    const message = AddUpdate(data);
    return message;
}

// Clear form fields
function clearFields() {
    document.querySelector("#manufacturerName").value = "";
    document.querySelector("#manufacturerURL").value = "";
    document.querySelector(".invisibleCheck").firstChild.checked = false;

    // clear the alert
    document.querySelector(".successMessage").innerText = "";
    document.querySelector(".errorMessage").innerText = "";
    document.querySelector(".errorList").innerHTML = "";
}

// #endregion

function ManufacturerManagementPage() {
    // #region declarations and Authenticate login - if user isn't valid, redirect to home page
    // eslint-disable-next-line no-unused-vars
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/");
        }
        // set Manufacturer name field dissable
        if (!location.state.ManufacturerName) {
            setIsDisabled(false);
        }
    }, [user, loading]);

    const location = useLocation();
    const [isDisabled, setIsDisabled] = useState(true);
    const [validated, setValidated] = useState(false);
    const [isHidden, setHidden] = useState(true);
    const [shouldHide, setHide] = useState(true);

    let returnMessage = {
        success: "",
        failure: "",
    };
    let errorMessage;
    let validationMessages = {
        manufacturerMessage: "",
        urlMessage: "",
    };

    // #endregion

    // #region Form Validation
    function validateManufacturer() {
        validateName();
        validateURL();

        return validationMessages;
    }

    function validateName() {
        const input = document.getElementById("manufacturerName");
        const validityState = input.validity;

        const valueMissingMessage = "Manufacturer Name is a required field.";
        const tooShortMessage = `Manufacturer Name must be at least ${MIN_MANUFACTURE_NAME_LENGTH} characters long.`;
        const tooLongMessage = `Manufacturer Name must be at most ${MAX_MANUFACTURER_NAME_LENGTH} characters long.`;

        if (validityState.valueMissing) {
            input.setCustomValidity(valueMissingMessage);
            validationMessages.manufacturerMessage = valueMissingMessage;
        } else if (validityState.tooShort) {
            input.setCustomValidity(tooShortMessage);
            validationMessages.manufacturerMessage = tooShortMessage;
        } else if (validityState.tooLong) {
            input.setCustomValidity(tooLongMessage);
            validationMessages.manufacturerMessage = tooLongMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.manufacturerMessage = "";
        }

        input.reportValidity();
    }

    function validateURL() {
        const input = document.getElementById("manufacturerURL");
        const validityState = input.validity;

        const valueMissingMessage = "Manufacturer URL is a required field.";
        const tooShortMessage = `Manufacturer URL must be at least ${MIN_MANUFACTURER_URL_LENGTH} characters long.`;
        const tooLongMessage = `Manufacturer URL must be at most ${MAX_MANUFACTURER_URL_LENGTH} characters long.`;
        const invalidMessage = `Manufacturer URL must be a valid URL.`;

        if (validityState.valueMissing) {
            input.setCustomValidity(valueMissingMessage);
            validationMessages.urlMessage = valueMissingMessage;
        } else if (validityState.tooShort) {
            input.setCustomValidity(tooShortMessage);
            validationMessages.urlMessage = tooShortMessage;
        } else if (validityState.tooLong) {
            input.setCustomValidity(tooLongMessage);
            validationMessages.urlMessage = tooLongMessage;
        } else if (validityState.invalidMessage) {
            input.setCustomValidity(invalidMessage);
            validationMessages.urlMessage = invalidMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.urlMessage = "";
        }

        input.reportValidity();
    }
    // #endregion

    // #region Form Handling
    // Save button event (Add/Update)
    const onSave = async (event) => {
        event.preventDefault();

        // declarations
        let errorHeading = document.querySelector(".errorMessage");
        let errorList = document.querySelector(".errorList");

        const form = event.currentTarget;
        const result = await confirm(
            "Is the manufacturer name correct? This cannot be updated later."
        );

        //clear old errors
        errorHeading.innerText = "";
        errorList.innerHTML = "";
        setHide(true);
        setHidden(true);

        if (result) {
            // validate form
            validationMessages = validateManufacturer();
            if (form.checkValidity() === false) {
                event.stopPropagation();
            }

            // shows validation
            setValidated(true);

            // check there are no errors
            // eslint-disable-next-line prettier/prettier
            if (Object.values(validationMessages).every(x => x === "" || x === null)) {
                returnMessage = saveManufacturerData();

                // disable Manufacturer name field
                setIsDisabled(true);
            } else {
                errorHeading.innerText = "Input not valid: ";
                setHide(false);
                // display error messages
                Object.values(validationMessages).map((errorMessage) => {
                    if (errorMessage != "") {
                        let li = document.createElement("li");
                        errorList.append(li);
                        li.innerText = errorMessage;
                    }
                });
            }
        }

        if (returnMessage.success != "") {
            setHidden(false);
            document.querySelector(".successMessage").innerText =
                returnMessage.success;
        } else if (returnMessage.failure != "") {
            setHide(false);
            document.querySelector(".errorMessage").innerText =
                returnMessage.failure;
        }
    };

    // Delete button event
    const onDelete = async (event) => {
        event.preventDefault();

        const result = await confirm(
            "Are you sure you want to delete this Manufacturer?"
        );

        if (result) {
            // check that manufacturer name exists
            // eslint-disable-next-line prettier/prettier
            if (location.state.ManufacturerName) {
                // info to pass
                const data = {
                    collection: "Manufacturer",
                    docId: document.querySelector("#manufacturerName").value,
                };

                returnMessage = DeleteDoc(data);
                setValidated(false);
                setIsDisabled(false);
                clearFields();
            } else {
                errorMessage = "No Manufacturer selected.";
            }
        }

        if (returnMessage.success != "") {
            setHidden(false);
            document.querySelector(".successMessage").innerText = returnMessage;
        } else if (errorMessage != "") {
            setHide(false);
            document.querySelector(".errorMessage").innerText = errorMessage;
        } else if (returnMessage.failure != "") {
            setHide(false);
            document.querySelector(".errorMessage").innerText = returnMessage;
        }
    };

    // Clear button event
    const onClear = (event) => {
        event.preventDefault();
        setIsDisabled(false);
        setValidated(false);
        setHidden(true);
        setHide(true);

        clearFields();
    };

    // #endregion

    return (
        <main className="max-container m-auto">
            <Container fluid>
                <h1 className="my-3">Manufacturer Management</h1>

                <div className="d-flex justify-content-center">
                    <DefaultButton
                        label="Go back to Manufacturer list"
                        title="customBtn"
                        handleClick={() => navigate("/manufacturer")}
                    />
                </div>

                <div className="col-md-6 mx-auto">
                    <Alert
                        className="successMessage my-3"
                        variant="success"
                        hidden={isHidden}
                    ></Alert>
                    <Alert
                        className="my-3"
                        variant="danger"
                        hidden={shouldHide}
                    >
                        <Alert.Heading className="errorMessage"></Alert.Heading>
                        <ul className="errorList"></ul>
                    </Alert>

                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={onSave}
                        className={styles.form + " mt-3"}
                    >
                        {/* Manufacturer Name section */}
                        <FormGroup className="my-3">
                            <FormLabel>Manufacturer Name:</FormLabel>
                            <FormControl
                                type="text"
                                id="manufacturerName"
                                defaultValue={
                                    location.state
                                        ? location.state.ManufacturerName
                                        : ""
                                }
                                disabled={isDisabled}
                                required
                                minLength={MIN_MANUFACTURE_NAME_LENGTH}
                                maxLength={MAX_MANUFACTURER_NAME_LENGTH}
                            ></FormControl>
                            <span className={styles.warning}>
                                * Due to database constraints the Manufacturer
                                name cannot be editied once saved.
                            </span>
                        </FormGroup>
                        {/* Manufacturer URL section */}
                        <FormGroup className="my-3">
                            <FormLabel>Manufacturer URL: </FormLabel>
                            <FormControl
                                type="text"
                                id="manufacturerURL"
                                defaultValue={
                                    location.state
                                        ? location.state.ManufacturerURL
                                        : ""
                                }
                                minLength={MIN_MANUFACTURER_URL_LENGTH}
                                maxLength={MAX_MANUFACTURER_URL_LENGTH}
                                pattern={VALID_MANUFACTURER_URL_REGEX}
                            ></FormControl>
                        </FormGroup>
                        {/* Visible section */}
                        <FormGroup className="my-3 d-flex gap-3">
                            <FormLabel>Visible: </FormLabel>
                            <Form.Check
                                inline
                                type="switch"
                                className="invisibleCheck"
                                defaultChecked={
                                    location.state
                                        ? location.state.Visible
                                        : false
                                }
                            ></Form.Check>
                        </FormGroup>
                        {/* Buttons */}
                        <Stack
                            direction="horizontal"
                            gap={3}
                            className="buttonGroup"
                        >
                            <SubmitButton
                                label="Save Manufacturer"
                                title="customBtn"
                            >
                                Save
                            </SubmitButton>
                            <DefaultButton
                                label="Delete"
                                title="deleteBtn"
                                handleClick={onDelete}
                            >
                                Delete
                            </DefaultButton>
                            <DefaultButton
                                label="Clear"
                                title="clearBtn"
                                handleClick={onClear}
                            >
                                Clear
                            </DefaultButton>
                        </Stack>
                    </Form>
                </div>
            </Container>
        </main>
    );
}

export default ManufacturerManagementPage;
