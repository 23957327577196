/* eslint-disable prettier/prettier */
/**
 * @author Jonathan Kryvenchuck
 * @author Russel Benito
 * @author Fred III Whiteman
 * @author Zachary Fedorak
 * @author Afi Ali
 * @version MAR-11-2022
 * @visiblename Employee Dashboard
 * @Description The main page for managing the site and routing to the database management pages
 */

// Bootstrap
import { Container, Card, Row, Col } from "react-bootstrap";

// React
import React, { useEffect } from "react";

// React Router
import { useNavigate } from "react-router-dom";

// Button
import { DefaultButton } from "../../components/button/Button";

// Firebase
import { auth, logout } from "../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";

// css
import styles from "./Dashboard.module.css";

function DashboardPage() {
    // Authenticate login - if user isn't valid, redirect to home page
    // eslint-disable-next-line no-unused-vars
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/");
        }
    }, [user, loading]);

    return (
        <main className="max-container page-height m-auto">
            <Container fluid>
                <div>
                    <h1 className="mb-3">Dashboard</h1>
                    <DefaultButton
                        label="Logout"
                        title="customBtn"
                        handleClick={logout}
                    />
                    <Row
                        xs={1}
                        md={1}
                        lg={3}
                        className="justify-content-between g-4 mt-1"
                    >
                        <Col>
                            <Card className={styles.cardStyle}>
                                <Card.Header as="h2">Manage Boats</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        View and manage all of the boats in the
                                        database.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <DefaultButton
                                        label="Manage"
                                        title="customBtn"
                                        handleClick={() => navigate("/boats")}
                                    />
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col>
                            <Card className={styles.cardStyle}>
                                <Card.Header as="h2">
                                    Manage Manufacturers
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        View and manage all of the manufacturers
                                        in the database.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    <DefaultButton
                                        label="Manage"
                                        title="customBtn"
                                        handleClick={() =>
                                            navigate("/manufacturer")
                                        }
                                    />
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col>
                            <Card className={styles.cardStyle}>
                                <Card.Header as="h2">Manage Blog</Card.Header>
                                <Card.Body>
                                    View and manage all of the blogs in the
                                    database.
                                </Card.Body>
                                <Card.Footer>
                                    <DefaultButton
                                        label="Manage"
                                        title="customBtn"
                                        handleClick={() =>
                                            navigate("/blog/list")
                                        }
                                    />
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col>
                            <Card className={styles.cardStyle}>
                                <Card.Header as="h2">Manage Popup Messages</Card.Header>
                                <Card.Body>
                                    View and manage the total displacement and Cost to Displacement Ratio messages.
                                </Card.Body>
                                <Card.Footer>
                                    <DefaultButton
                                        label="Manage"
                                        title="customBtn"
                                        handleClick={() => navigate("/Popups")}
                                    />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </main>
    );
}

export default DashboardPage;
