/**
 * @author Jonathan Kryvenchuk
 * @author Fred-III-Whiteman
 * @version FEB-04-2022
 * @description Config file for Firebase
 * @see https://youtu.be/ZLcU-bwxzXQ?t=915
 * for authentication
 * @see https://www.youtube.com/watch?v=MEAjrlQ35HQ
 */

// Firebase
import Firebase from "firebase/compat/app"; // v9 compat packages are API compatible with v8 code
import "firebase/compat/firestore";

//Firebase auth
import { getAuth, signInWithEmailAndPassword, signOut } from "@firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = {
    apiKey: "AIzaSyBtHGDmVhsPNHzbOdimH0Ximl0yFrJiDqQ",
    authDomain: "drew-dietze.firebaseapp.com",
    databaseURL: "https://drew-dietze-default-rtdb.firebaseio.com",
    projectId: "drew-dietze",
    storageBucket: "drew-dietze.appspot.com",
    messagingSenderId: "834537354628",
    appId: "1:834537354628:web:01d253cafed06424e7d9d1",
    measurementId: "G-EQMYNR0B3N",
};

// Initialize Firebase
const firebase = Firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

// context for firebase authentication
const auth = getAuth(firebase);

// login with email
const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.log(err);
        alert(
            "The email and/or password entered are invalid, please try again. "
        );
    }
};

// sign out
const logout = () => {
    signOut(auth);
};

export { firebase, db, auth, logInWithEmailAndPassword, logout };
