/**
 * @author Fred-III_Whiteman
 * @version APR-05-2022
 * @visiblename Modal Message Management
 * @Description This page will show a selected Modal Message from the Comparison page and allow the user to update it.
 */

//#region Imports
// React
import { useEffect, useState } from "react";

// React Router
import { useLocation, useNavigate } from "react-router-dom";

// Firebase
import { auth } from "../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import AddUpdate from "../../components/crudFunctions/AddUpdate";

// Bootstrap
import {
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Container,
    Alert,
    Stack,
} from "react-bootstrap";

// Button
import { DefaultButton, SubmitButton } from "../../components/button/Button";

// CSS
import styles from "./ManagementForm.module.css";

//#endregion

// #region configurable constants
// Valid Total Displacement header length
const MIN_HEADER_LENGTH = 1;
const MAX_HEADER_LENGTH = 50;

// Valid Total Displacement message length
const MIN_MESSAGE_LENGTH = 1;
const MAX_MESSAGE_LENGTH = 500;

// #endregion

// #region Helper Functions
function saveModalMessage(docKey) {
    // info to pass
    const data = {
        collection: "ModalMessages",
        docId: docKey,
        info: {
            Header: document.querySelector("#ModalHeading").value,
            Message: document.querySelector("#ModalMessage").value,
        },
    };

    // add data
    const message = AddUpdate(data);
    return message;
}

// Clear form fields
function clearFields() {
    document.querySelector("#ModalHeading").value = "";
    document.querySelector("#ModalMessage").value = "";

    // clear the alert
    document.querySelector(".successMessage").innerText = "";
    document.querySelector(".errorMessage").innerText = "";
    document.querySelector(".errorList").innerHTML = "";
}

// #endregion

function ModalMessageManagementPage() {
    //#region Authenticate login - if user isn't valid, redirect to home page
    // eslint-disable-next-line no-unused-vars
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/");
        }
    }, [user, loading]);
    //#endregion

    //#region Declare variables
    const [validated, setValidated] = useState(false);
    const [isHidden, setHidden] = useState(true);
    const [shouldHide, setHide] = useState(true);

    let returnMessage = {
        success: "",
        failure: "",
    };
    let validationMessages = {
        ModalHeader: "",
        ModalMessage: "",
    };
    //#endregion

    // #region Form Validation
    function validateModal() {
        validateModalHeader();
        validateModalMessage();

        return validationMessages;
    }

    function validateModalHeader() {
        const input = document.getElementById("ModalHeading");
        const validityState = input.validity;

        const valueMissingMessage = "The header is a required field.";
        const tooShortMessage = `The header must be at least ${MIN_HEADER_LENGTH} characters long.`;
        const tooLongMessage = `The header must be at most ${MAX_HEADER_LENGTH} characters long.`;

        if (validityState.valueMissing) {
            input.setCustomValidity(valueMissingMessage);
            validationMessages.ModalHeader = valueMissingMessage;
        } else if (validityState.tooShort) {
            input.setCustomValidity(tooShortMessage);
            validationMessages.ModalHeader = tooShortMessage;
        } else if (validityState.tooLong) {
            input.setCustomValidity(tooLongMessage);
            validationMessages.ModalHeader = tooLongMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.ModalHeader = "";
        }

        input.reportValidity();
    }

    function validateModalMessage() {
        const input = document.getElementById("ModalMessage");
        const validityState = input.validity;

        const valueMissingMessage =
            "Total Displacement Message is a required field.";
        const tooShortMessage = `Total Displacement Message must be at least ${MIN_MESSAGE_LENGTH} characters long.`;
        const tooLongMessage = `Total Displacement Message must be at most ${MAX_MESSAGE_LENGTH} characters long.`;

        if (validityState.valueMissing) {
            input.setCustomValidity(valueMissingMessage);
            validationMessages.ModalMessage = valueMissingMessage;
        } else if (validityState.tooShort) {
            input.setCustomValidity(tooShortMessage);
            validationMessages.ModalMessage = tooShortMessage;
        } else if (validityState.tooLong) {
            input.setCustomValidity(tooLongMessage);
            validationMessages.ModalMessage = tooLongMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.ModalMessage = "";
        }

        input.reportValidity();
    }
    // #endregion

    // #region Form Handling
    // Save button event (Add/Update)
    const onSave = async (event) => {
        event.preventDefault();

        // declarations
        let errorHeading = document.querySelector(".errorMessage");
        let errorList = document.querySelector(".errorList");

        const form = event.currentTarget;
        const result = await confirm(
            "Are you sure you want to save this message?"
        );

        //clear old errors
        errorHeading.innerText = "";
        errorList.innerHTML = "";
        setHide(true);
        setHidden(true);

        if (result) {
            // validate form
            validationMessages = validateModal();
            if (form.checkValidity() === false) {
                event.stopPropagation();
            }

            // shows validation
            setValidated(true);

            let docKey = location.state.Key;

            // check there are no errors
            // eslint-disable-next-line prettier/prettier
            if (Object.values(validationMessages).every(x => x === "" || x === null)) {
                returnMessage = saveModalMessage(docKey);
            } else {
                errorHeading.innerText = "Input not valid: ";
                setHide(false);
                // display error messages
                Object.values(validationMessages).map((errorMessage) => {
                    if (errorMessage != "") {
                        let li = document.createElement("li");
                        errorList.append(li);
                        li.innerText = errorMessage;
                    }
                });
            }
        }

        if (returnMessage.success != "") {
            setHidden(false);
            document.querySelector(".successMessage").innerText =
                returnMessage.success;
        } else if (returnMessage.failure != "") {
            setHide(false);
            document.querySelector(".errorMessage").innerText =
                returnMessage.failure;
        }
    };

    // Clear button event
    const onClear = (event) => {
        event.preventDefault();
        setValidated(false);
        setHidden(true);
        setHide(true);

        clearFields();
    };

    // #endregion

    return (
        <main className="max-container page-height m-auto">
            <Container fluid>
                <div>
                    <h1 className="mb-3">Popup Message Management</h1>
                    <div className="d-flex justify-content-center">
                        <DefaultButton
                            label="Go back to Dashboard"
                            title="customBtn"
                            handleClick={() => navigate("/popups")}
                        />
                    </div>
                    <div className="col-md-6 mx-auto">
                        <Alert
                            className="successMessage my-3"
                            variant="success"
                            hidden={isHidden}
                        ></Alert>
                        <Alert
                            className="my-3"
                            variant="danger"
                            hidden={shouldHide}
                        >
                            <Alert.Heading className="errorMessage"></Alert.Heading>
                            <ul className="errorList"></ul>
                        </Alert>

                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={onSave}
                            className={styles.form + " mt-3"}
                        >
                            {/* Heading section */}
                            <FormGroup className="my-3">
                                <FormLabel>Heading</FormLabel>
                                <FormControl
                                    type="text"
                                    id="ModalHeading"
                                    defaultValue={
                                        location.state
                                            ? location.state.Header
                                            : ""
                                    }
                                    required
                                    minLength={MIN_HEADER_LENGTH}
                                    maxLength={MAX_HEADER_LENGTH}
                                ></FormControl>
                            </FormGroup>
                            {/* Message section */}
                            <FormGroup className="my-6">
                                <FormLabel>Message </FormLabel>
                                <FormControl
                                    as="textarea"
                                    id="ModalMessage"
                                    defaultValue={
                                        location.state
                                            ? location.state.Message
                                            : ""
                                    }
                                    required
                                    minLength={MIN_MESSAGE_LENGTH}
                                    maxLength={MAX_MESSAGE_LENGTH}
                                ></FormControl>
                            </FormGroup>
                            {/* Buttons */}
                            <Stack
                                direction="horizontal"
                                gap={3}
                                className="buttonGroup mt-3"
                            >
                                <SubmitButton
                                    label="Save Message"
                                    title="customBtn"
                                >
                                    Save
                                </SubmitButton>
                                <DefaultButton
                                    label="Clear"
                                    title="clearBtn"
                                    handleClick={onClear}
                                >
                                    Clear
                                </DefaultButton>
                            </Stack>
                        </Form>
                    </div>
                </div>
            </Container>
        </main>
    );
}

export default ModalMessageManagementPage;
