/**
 * @author Afi ALi
 * @author Jonathan Kryvenchuk
 * @version MAR-15-2022
 * @visibleName Contact Us Page
 * @description This page is for users to be able to contact Drew through email.
 */

// Bootstrap
import {
    Container,
    Form,
    FormGroup,
    FormLabel,
    FormControl,
} from "react-bootstrap";

// Custom components
import { SubmitButton } from "../components/button/Button";

// css
import "./ContactUs.css";

function ContactUsPage() {
    /**
     * Gets the values of the form inputs and creates URL parameters
     * @param event form submit event
     * @returns URL parameters for mailto
     */
    function getContactInputs(event) {
        let subjectElement = event.target.elements["contactSubject"];
        let messageElement = event.target.elements["contactMessage"];
        let nameElement = event.target.elements["contactName"];
        let mailtoParams = "";

        if (valueIsNotEmpty(subjectElement.value)) {
            mailtoParams += ` - ${encodeURIComponent(subjectElement.value)}`;
        }

        if (
            valueIsNotEmpty(messageElement.value) &&
            valueIsNotEmpty(nameElement.value)
        ) {
            //%0D%0A represents a line break
            mailtoParams += `&body=${encodeURIComponent(
                messageElement.value
            )}%0D%0A%0D%0A- ${encodeURIComponent(nameElement.value)}`;
        } else if (valueIsNotEmpty(messageElement.value)) {
            mailtoParams += `&body=${encodeURIComponent(messageElement.value)}`;
        } else if (valueIsNotEmpty(nameElement.value)) {
            mailtoParams += `&body=- ${encodeURIComponent(nameElement.value)}`;
        }

        return mailtoParams;
    }

    function showHelp() {
        document.querySelector("#contactHelp").classList.remove("d-none");
    }

    /**
     * Checks to see whether the value parameter is not empty
     * @param value the value to check
     * @param type (optional) data type, to check whether the value is the correct type
     * @returns boolean (true or false)
     */
    function valueIsNotEmpty(value, type = "") {
        if (type.toLowerCase() === "number") {
            if (isNaN(value) || typeof value !== "number") {
                return false;
            }
        }

        if (value !== null && value !== undefined && value !== "") {
            return true;
        }
        return false;
    }

    return (
        <main className="flex-fill">
            <Container className="max-container mx-auto" fluid>
                <div>
                    <h1 className="text-center pt-5 mb-4 text-uppercase">
                        Contact
                    </h1>
                    <Form
                        className="col-md-8 mx-auto col-lg-4 contactForm mb-3"
                        onSubmit={(event) => {
                            //prevent event default so page doesn't reload
                            event.preventDefault();

                            //get the form input values and redirect the user to their default email program
                            window.location = `mailto:surfselect.ca@gmail.com?subject=SurfSelect Contact${getContactInputs(
                                event
                            )}`;

                            //just incase the user does not have their email program setup, display a message underneath the form.
                            showHelp();
                        }}
                    >
                        <FormGroup className="mb-3">
                            <FormLabel>Name</FormLabel>
                            <FormControl
                                name="contactName"
                                id="contactName"
                                type="text"
                                placeholder="Full name..."
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <FormLabel>Subject</FormLabel>
                            <FormControl
                                name="contactSubject"
                                id="contactSubject"
                                type="text"
                                placeholder="Subject topic..."
                            />
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <FormLabel>Message</FormLabel>
                            <FormControl
                                name="contactMessage"
                                id="contactMessage"
                                as="textarea"
                                placeholder="Type message here..."
                            />
                        </FormGroup>
                        <FormGroup className="sendbutton">
                            <SubmitButton label="Send" title="contactBtn" />
                        </FormGroup>
                    </Form>
                    <div className="col-sm-8 mx-auto col-lg-4">
                        <p
                            className="d-none text-muted text-center mt-2"
                            id="contactHelp"
                        >
                            Not sending? Send your message to:{" "}
                            <a
                                className="link-info"
                                href="mailto:surfselect.ca@gmail.com"
                            >
                                surfselect.ca@gmail.com
                            </a>
                        </p>
                    </div>
                </div>
            </Container>
        </main>
    );
}

export default ContactUsPage;
