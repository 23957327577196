/**
 * @author Jonathan Kryvenchuk
 * @author Omer Ahmed
 * @author Afi Ali
 * @author Russel Benito
 * @author Robert Kelly
 * @version APR-06-2022
 * @visibleName Comparison Page
 * @description This is the main page of the site where users will be able to select 2 different boats to compare specs.
 */

//#region Imports
// React
import React, { useState } from "react";

// React Router
import { useLocation, Link } from "react-router-dom";

// Components
import DataFetcher from "../components/dataFetcher/DataFetcher";
import BoatSelect from "../components/comparison/BoatSelect";
import TableHeaderCaret from "../components/comparison/TableHeaderCaret";

// Icons
import RepeatIcon from "../components/icons/RepeatIcon";
import DeltaIcon from "../components/icons/DeltaIcon";
import QuestionCircleFill from "../components/icons/QuestionCircleFill";

// Bootstrap
import {
    Container,
    Table,
    FormControl,
    Button,
    Row,
    InputGroup,
    OverlayTrigger,
    Tooltip,
    Modal,
} from "react-bootstrap";

// CSS
import styles from "./Comparison.styles.module.css";

// Graph imports
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

//#endregion

// Graph variables
let myChart;
let labels;
let data;

/** Read and sort boats from Database */
let boatCollection;

function ComparisonPage() {
    //#region Get state from Homepage
    const location = useLocation();
    let locationValid = false;
    if (valueIsNotEmpty(location)) {
        if (valueIsNotEmpty(location.state)) {
            if (valueIsNotEmpty(location.state.boatKey)) {
                if (valueIsNotEmpty(location.state.boatKey.key)) {
                    locationValid = true;
                }
            }
        }
    }
    const boatKey = locationValid ? location.state.boatKey.key : null;
    //#endregion

    //#region Read and sort boats from Database
    boatCollection = DataFetcher("Boat");
    if (boatCollection.length > 0) {
        boatCollection = boatCollection
            .filter((boat) => {
                return boat.Visible;
            })
            .sort((boat1, boat2) => {
                let manufacturerSorted = [
                    boat1.Manufacturer,
                    boat2.Manufacturer,
                ].sort();
                if (boat1.Manufacturer !== boat2.Manufacturer) {
                    //Ascending order
                    return manufacturerSorted[0] === boat2.Manufacturer
                        ? 1
                        : -1;
                } else {
                    if (boat1.Model !== boat2.Model) {
                        let modelSorted = [boat1.Model, boat2.Model].sort();
                        //Ascending order
                        return modelSorted[0] === boat2.Model ? 1 : -1;
                    } else {
                        let yearSorted = [boat1.Year, boat2.Year].sort();
                        //Descending order
                        return yearSorted[0] === boat2.Year ? -1 : 1;
                    }
                }
            });
    }
    //#endregion

    //#region Process Boat Data to be displayed in Boat Select
    let count = 0;
    const processedBoatCollection = boatCollection.map((boat) => {
        let processedBoat = Object.assign({}, boat);
        processedBoat.Year = processedBoat.Year.toString();
        processedBoat.Index = count;
        count++;
        return processedBoat;
    });
    //#endregion

    //#region Read Manufacturers from the Database
    let manufacturerCollection = DataFetcher("Manufacturer");

    if (manufacturerCollection.length > 0) {
        manufacturerCollection = manufacturerCollection.filter(
            (manufacturer) => {
                return manufacturer.Visible;
            }
        );
    }

    let modalMessagesCollection = DataFetcher("ModalMessages");
    //#endregion

    //#region State Variables
    const [boatIndexes, setBoatIndexes] = useState([null, null]);

    const [pageLoaded, setPageLoaded] = useState(false);

    const [boatSelectInputs, setBoatSelectInputs] = useState([null, null]);

    const [summaryModalShow, setSummaryModalShow] = useState(false);
    const [displacementModalShow, setDisplacementModalShow] = useState(false);
    const [differencePercentageModalShow, setDifferencePercentageModalShow] =
        useState(false);

    const [differencesTooltipHoverShow, setDifferencesTooltipHoverShow] =
        useState(false);
    //#endregion

    /** Graph Variables */
    const [graphIsDrawn, setGraphIsDrawn] = useState(false);

    //#region Receive boat from Homepage, and set the first BoatSelect
    getBoatFromHomepage();
    function getBoatFromHomepage() {
        if (
            boatKey !== null &&
            document.querySelector("#boatSelect1") !== null &&
            pageLoaded == false
        ) {
            const homepageBoat = processedBoatCollection.find(
                (boat) => boat.key === boatKey
            );

            if (
                valueIsNotEmpty(homepageBoat) &&
                manufacturerCollection.length > 0
            ) {
                setPageLoaded(true);
                getBoatFromDropdown(homepageBoat.Index, 1);
            }
        }
    }
    //#endregion

    /**
     * Get Boat from BoatSelect (Dropdown), usually run when onChange event fires
     * If no boat is selected, clear the column
     * @param index Index of selected boat from boatCollection
     * @param idNum BoatSelect and column id
     */
    function getBoatFromDropdown(index, idNum) {
        if (valueIsNotEmpty(index)) {
            let newBoatIndexes = boatIndexes;
            newBoatIndexes[idNum - 1] = index;
            setBoatIndexes(newBoatIndexes);

            if (boatIndexes[idNum - 1] !== null) {
                const boat = boatCollection[boatIndexes[idNum - 1]];
                boatSelectInputs[idNum - 1].placeholder = `${
                    boat.Manufacturer ? boat.Manufacturer : ""
                }: ${boat.Model ? boat.Model : ""} - ${
                    boat.Year ? boat.Year : ""
                }`;
            }

            renderBoat(idNum);
            renderDifferences();
        } else {
            let newBoatIndexes = boatIndexes;
            newBoatIndexes[idNum - 1] = null;
            setBoatIndexes(newBoatIndexes);

            boatSelectInputs[idNum - 1].placeholder = "Select a boat...";

            clearColumn(idNum);
            renderDifferences();
        }
    }

    /**
     * Clear the price field of the specified id
     * @param idNum Price field and Column id
     */
    function clearPrice(idNum) {
        document.querySelector(`#boatPrice${idNum}`).value = "";
    }

    /**
     * Clear the all the fields of the specified column
     * @param idNum BoatSelect and Column id
     */
    function clearColumn(idNum) {
        //MODEL
        if (idNum != "Diff" && idNum != "DiffMobile") {
            document.querySelector(`#boatModel${idNum}`).innerText = "--";
        }

        if (idNum != "DiffMobile") {
            //MANUFACTURER
            document.querySelector(`#boatManufacturer${idNum}`).innerText =
                "--";

            //YEAR
            document.querySelector(`#boatYear${idNum}`).innerText = "--";
        }

        //LENGTH
        document.querySelector(`#boatLength${idNum}`).innerText = "--";

        //SEATING
        document.querySelector(`#boatSeatingCapacity${idNum}`).innerText = "--";

        //DRY WEIGHT
        document.querySelector(`#boatDryWeight${idNum}`).innerText = "--";

        //MAX STOCK BALLAST
        document.querySelector(`#boatMaxStockBallast${idNum}`).innerText = "--";

        //DISPLACEMENT
        document.querySelector(`#boatDisplacement${idNum}`).innerText = "--";

        //WEIGHT CAPACITY
        document.querySelector(`#boatWeightCapacity${idNum}`).innerText = "--";

        //TOTAL DISPLACEMENT
        document.querySelector(`#boatTotalDisplacement${idNum}`).innerText =
            "--";

        //DISPLACEMENT / FOOT
        document.querySelector(`#boatDfoot${idNum}`).innerText = "--";
    }

    /**
     * Render the selected boat to the specified column
     * @param idNum BoatSelect and Column id
     */
    function renderBoat(idNum) {
        if (boatIndexes[idNum - 1] !== null) {
            const boat = boatCollection[boatIndexes[idNum - 1]];

            if (boat !== null) {
                //MODEL
                document.querySelector(`#boatModel${idNum}`).innerText =
                    valueIsNotEmpty(boat.Model) ? boat.Model : "--";

                //MANUFACTURER

                const boatManufacturer = manufacturerCollection.filter(
                    (manufacturer) => {
                        if (manufacturer.key === boat.Manufacturer) {
                            return true;
                        }
                        return false;
                    }
                );

                let manufacturerHTML = valueIsNotEmpty(boat.Manufacturer)
                    ? boat.Manufacturer
                    : "--";
                if (boatManufacturer.length === 1) {
                    if ("ManufacturerURL" in boatManufacturer[0]) {
                        let manufacturerStyles =
                            idNum === 1 ? styles.boatCol1 : styles.boatCol2;
                        manufacturerHTML = valueIsNotEmpty(boat.Manufacturer)
                            ? `<a href="${boatManufacturer[0].ManufacturerURL}" class="${manufacturerStyles}" target="_blank" rel="noopener noreferrer">${boat.Manufacturer}</a>`
                            : "--";
                    }
                }
                document.querySelector(`#boatManufacturer${idNum}`).innerHTML =
                    manufacturerHTML;

                //YEAR
                document.querySelector(`#boatYear${idNum}`).innerText =
                    valueIsNotEmpty(boat.Year, "number") ? boat.Year : "--";

                //LENGTH
                document.querySelector(`#boatLength${idNum}`).innerText =
                    valueIsNotEmpty(boat.Length, "number")
                        ? boat.Length.toFixed(2) + " ft"
                        : "--";

                //SEATING
                document.querySelector(
                    `#boatSeatingCapacity${idNum}`
                ).innerText = valueIsNotEmpty(boat.SeatingCapacity, "number")
                    ? boat.SeatingCapacity
                    : "--";

                //DRY WEIGHT
                document.querySelector(`#boatDryWeight${idNum}`).innerText =
                    valueIsNotEmpty(boat.DryWeight, "number")
                        ? boat.DryWeight + " lbs"
                        : "--";

                //MAX STOCK BALLAST
                document.querySelector(
                    `#boatMaxStockBallast${idNum}`
                ).innerText = valueIsNotEmpty(boat.MaxStockBallast, "number")
                    ? boat.MaxStockBallast + " lbs"
                    : "--";

                //DISPLACEMENT
                if (
                    valueIsNotEmpty(boat.DryWeight, "number") &&
                    valueIsNotEmpty(boat.MaxStockBallast, "number")
                ) {
                    document.querySelector(
                        `#boatDisplacement${idNum}`
                    ).innerText =
                        boat.DryWeight + boat.MaxStockBallast + " lbs";
                } else {
                    document.querySelector(
                        `#boatDisplacement${idNum}`
                    ).innerText = "--";
                }

                //WEIGHT CAPACITY
                document.querySelector(
                    `#boatWeightCapacity${idNum}`
                ).innerText = valueIsNotEmpty(boat.WeightCapacity, "number")
                    ? boat.WeightCapacity + " lbs"
                    : "--";

                //TOTAL DISPLACEMENT
                document.querySelector(
                    `#boatTotalDisplacement${idNum}`
                ).innerText = valueIsNotEmpty(boat.TotalDisplacement, "number")
                    ? boat.TotalDisplacement + " lbs"
                    : "--";

                //DISPLACEMENT / FOOT
                document.querySelector(`#boatDfoot${idNum}`).innerText =
                    valueIsNotEmpty(boat.Dfoot, "number")
                        ? boat.Dfoot + " lbs"
                        : "--";
            }
        } else {
            return;
        }
    }

    /** Render the differences column and difference percentage column, if two boats are selected */
    function renderDifferences() {
        document
            .querySelector("#boatTotalDisplacement1")
            .classList.remove(styles.highlightCellCol1);
        document
            .querySelector("#boatTotalDisplacement2")
            .classList.remove(styles.highlightCellCol2);

        if (!boatIndexes.includes(null)) {
            const boat1 = boatCollection[boatIndexes[0]];
            const boat2 = boatCollection[boatIndexes[1]];
            if (boat1 !== null && boat2 !== null) {
                //LENGTH
                const boatLengthDiff =
                    document.querySelector(`#boatLengthDiff`);
                if (
                    valueIsNotEmpty(boat1.Length, "number") &&
                    valueIsNotEmpty(boat2.Length, "number")
                ) {
                    boatLengthDiff.innerText =
                        Math.abs(boat1.Length - boat2.Length).toFixed(2) +
                        " ft";
                } else {
                    boatLengthDiff.innerText = "--";
                }
                document.querySelector(`#boatLengthDiffMobile`).innerText =
                    boatLengthDiff.innerText;

                //SEATING CAPACITY
                const boatSeatingCapacityDiff = document.querySelector(
                    `#boatSeatingCapacityDiff`
                );
                if (
                    valueIsNotEmpty(boat1.SeatingCapacity, "number") &&
                    valueIsNotEmpty(boat2.SeatingCapacity, "number")
                ) {
                    boatSeatingCapacityDiff.innerText = Math.abs(
                        boat1.SeatingCapacity - boat2.SeatingCapacity
                    );
                } else {
                    boatSeatingCapacityDiff.innerText = "--";
                }
                document.querySelector(
                    `#boatSeatingCapacityDiffMobile`
                ).innerText = boatSeatingCapacityDiff.innerText;

                //DRY WEIGHT
                const boatDryWeightDiff =
                    document.querySelector(`#boatDryWeightDiff`);
                if (
                    valueIsNotEmpty(boat1.DryWeight, "number") &&
                    valueIsNotEmpty(boat2.DryWeight, "number")
                ) {
                    boatDryWeightDiff.innerText =
                        Math.abs(boat1.DryWeight - boat2.DryWeight) + " lbs";
                } else {
                    boatDryWeightDiff.innerText = "--";
                }
                document.querySelector(`#boatDryWeightDiffMobile`).innerText =
                    boatDryWeightDiff.innerText;

                //MAX STOCK BALLAST
                const boatMaxStockBallastDiff = document.querySelector(
                    `#boatMaxStockBallastDiff`
                );
                if (
                    valueIsNotEmpty(boat1.MaxStockBallast, "number") &&
                    valueIsNotEmpty(boat2.MaxStockBallast, "number")
                ) {
                    boatMaxStockBallastDiff.innerText =
                        Math.abs(
                            boat1.MaxStockBallast - boat2.MaxStockBallast
                        ) + " lbs";
                } else {
                    boatMaxStockBallastDiff.innerText = "--";
                }
                document.querySelector(
                    `#boatMaxStockBallastDiffMobile`
                ).innerText = boatMaxStockBallastDiff.innerText;

                //DISPLACEMENT
                const boatDisplacementDiff = document.querySelector(
                    `#boatDisplacementDiff`
                );
                if (
                    valueIsNotEmpty(boat1.DryWeight, "number") &&
                    valueIsNotEmpty(boat1.MaxStockBallast, "number") &&
                    valueIsNotEmpty(boat2.DryWeight, "number") &&
                    valueIsNotEmpty(boat2.MaxStockBallast, "number")
                ) {
                    const boatDisplacementDiff1 =
                        boat1.DryWeight + boat1.MaxStockBallast;
                    const boatDisplacementDiff2 =
                        boat2.DryWeight + boat2.MaxStockBallast;

                    boatDisplacementDiff.innerText =
                        Math.abs(
                            boatDisplacementDiff1 - boatDisplacementDiff2
                        ) + " lbs";
                } else {
                    boatDisplacementDiff.innerText = "--";
                }
                document.querySelector(
                    `#boatDisplacementDiffMobile`
                ).innerText = boatDisplacementDiff.innerText;

                //WEIGHT CAPACITY
                const boatWeightCapacityDiff = document.querySelector(
                    `#boatWeightCapacityDiff`
                );
                if (
                    valueIsNotEmpty(boat1.WeightCapacity, "number") &&
                    valueIsNotEmpty(boat2.WeightCapacity, "number")
                ) {
                    boatWeightCapacityDiff.innerText =
                        Math.abs(boat1.WeightCapacity - boat2.WeightCapacity) +
                        " lbs";
                } else {
                    boatWeightCapacityDiff.innerText = "--";
                }
                document.querySelector(
                    `#boatWeightCapacityDiffMobile`
                ).innerText = boatWeightCapacityDiff.innerText;

                //TOTAL DISPLACEMENT
                let totalDisplacementDiff = null;
                const boatTotalDisplacementDiff = document.querySelector(
                    `#boatTotalDisplacementDiff`
                );
                if (
                    valueIsNotEmpty(boat1.TotalDisplacement, "number") &&
                    valueIsNotEmpty(boat2.TotalDisplacement, "number")
                ) {
                    totalDisplacementDiff =
                        boat1.TotalDisplacement - boat2.TotalDisplacement;
                    boatTotalDisplacementDiff.innerText =
                        totalDisplacementDiff + " lbs";

                    if (boat1.TotalDisplacement > boat2.TotalDisplacement) {
                        // Make the cell in boat 1's column blue.
                        // Make the cell in the other column not blue.
                        document
                            .querySelector("#boatTotalDisplacement1")
                            .classList.add(styles.highlightCellCol1);
                    } else if (
                        boat1.TotalDisplacement < boat2.TotalDisplacement
                    ) {
                        // Make the cell in boat 2's column blue.
                        // Make the cell in the other column not blue.
                        document
                            .querySelector("#boatTotalDisplacement2")
                            .classList.add(styles.highlightCellCol2);
                    }
                    // Note: the code for removing the blue styling is at the top of renderDifferences.
                } else {
                    boatTotalDisplacementDiff.innerText = "--";
                }
                document.querySelector(
                    `#boatTotalDisplacementDiffMobile`
                ).innerText = boatTotalDisplacementDiff.innerText;

                const boatTotalDisplacementDiffPercent = document.querySelector(
                    `#boatTotalDisplacementDiffPercent`
                );
                if (
                    valueIsNotEmpty(totalDisplacementDiff, "number") &&
                    valueIsNotEmpty(boat2.TotalDisplacement, "number")
                ) {
                    const averageTotalDisplacement =
                        (boat1.TotalDisplacement + boat2.TotalDisplacement) / 2;
                    boatTotalDisplacementDiffPercent.innerText =
                        (
                            (totalDisplacementDiff / averageTotalDisplacement) *
                            100
                        ).toFixed(2) + "%";
                } else {
                    boatTotalDisplacementDiffPercent.innerText = "--";
                }
                document.querySelector(
                    `#boatTotalDisplacementDiffPercentMobile`
                ).innerText = boatTotalDisplacementDiffPercent.innerText;

                //DISPLACEMENT / FOOT
                let dfootDiff = null;
                const boatDfootDiff = document.querySelector(`#boatDfootDiff`);
                if (
                    valueIsNotEmpty(boat1.Dfoot, "number") &&
                    valueIsNotEmpty(boat2.Dfoot, "number")
                ) {
                    dfootDiff = boat1.Dfoot - boat2.Dfoot;
                    boatDfootDiff.innerText = dfootDiff + " lbs";
                } else {
                    boatDfootDiff.innerText = "--";
                }
                document.querySelector(`#boatDfootDiffMobile`).innerText =
                    boatDfootDiff.innerText;

                const boatDfootDiffPercent = document.querySelector(
                    `#boatDfootDiffPercent`
                );
                if (
                    valueIsNotEmpty(dfootDiff, "number") &&
                    valueIsNotEmpty(boat2.Dfoot, "number")
                ) {
                    const averageDfoot = (boat1.Dfoot + boat2.Dfoot) / 2;
                    boatDfootDiffPercent.innerText =
                        ((dfootDiff / averageDfoot) * 100).toFixed(2) + "%";
                } else {
                    boatDfootDiffPercent.innerText = "--";
                }
                document.querySelector(
                    `#boatDfootDiffPercentMobile`
                ).innerText = boatDfootDiffPercent.innerText;

                renderDifferencesPrice();
            }
        } else {
            clearColumn("Diff");
            clearColumn("DiffMobile");

            document.querySelector(
                "#boatTotalDisplacementDiffPercent"
            ).innerText = "--";
            document.querySelector(
                "#boatTotalDisplacementDiffPercentMobile"
            ).innerText = "--";

            document.querySelector("#boatDfootDiffPercent").innerText = "--";
            document.querySelector("#boatDfootDiffPercentMobile").innerText =
                "--";

            document.querySelector("#boatDiffSummary").innerText = "";
            document
                .querySelector("#boatDiffSummary")
                .parentElement.classList.add("d-none");

            renderDifferencesPrice();
        }
    }

    /** Render the price differences field and price difference percentage field, if two prices are entered */
    function renderDifferencesPrice() {
        //PRICE
        let priceDiff = null;
        const boatPrice1 = document.querySelector(`#boatPrice1`);
        const boatPrice2 = document.querySelector(`#boatPrice2`);
        const boatPriceDiff = document.querySelector(`#boatPriceDiff`);
        if (
            valueIsNotEmpty(Number(boatPrice1.value), "number") &&
            valueIsNotEmpty(Number(boatPrice2.value), "number") &&
            valueIsNotEmpty(boatPrice1.value) &&
            valueIsNotEmpty(boatPrice2.value)
        ) {
            /**
             * How to format numbers as currency strings
             * @see https://stackoverflow.com/a/16233919
             */
            let formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            });

            priceDiff = Number(boatPrice1.value) - Number(boatPrice2.value);
            boatPriceDiff.innerText = formatter.format(priceDiff);
        } else {
            boatPriceDiff.innerText = "--";
        }
        document.querySelector(`#boatPriceDiffMobile`).innerText =
            boatPriceDiff.innerText;

        const boatPriceDiffPercent = document.querySelector(
            `#boatPriceDiffPercent`
        );
        if (valueIsNotEmpty(priceDiff, "number")) {
            const averagePrice =
                (Number(boatPrice1.value) + Number(boatPrice2.value)) / 2;
            if (
                Math.abs(averagePrice) > 0 &&
                Number(boatPrice1.value) > 0 &&
                Number(boatPrice2.value) > 0
            ) {
                boatPriceDiffPercent.value =
                    ((priceDiff / Number(averagePrice)) * 100).toFixed(2) + "%";
            } else {
                boatPriceDiffPercent.value = "--";
            }
        } else {
            boatPriceDiffPercent.value = "--";
        }
        document.querySelector(`#boatPriceDiffPercentMobile`).innerText =
            boatPriceDiffPercent.value;

        if (!boatIndexes.includes(null)) {
            renderDifferencesSummary();
        }
    }

    /** Render the difference summary underneath the tables */
    function renderDifferencesSummary() {
        if (!boatIndexes.includes(null)) {
            if (
                valueIsNotEmpty(
                    boatCollection[boatIndexes[0]].TotalDisplacement,
                    "number"
                ) &&
                valueIsNotEmpty(
                    boatCollection[boatIndexes[1]].TotalDisplacement,
                    "number"
                )
            ) {
                const boatModel = boatCollection[boatIndexes[0]].Model;

                const boatTotalDisplacement1 =
                    boatCollection[boatIndexes[0]].TotalDisplacement;
                const boatTotalDisplacement2 =
                    boatCollection[boatIndexes[1]].TotalDisplacement;
                let boatTotalDisplacementDiffPercent = "";

                const boatPrice1 = document.querySelector(`#boatPrice1`);
                const boatPrice2 = document.querySelector(`#boatPrice2`);

                let summaryText = "";
                let summaryPrice = "";
                let summaryCostRatio = "";

                let moreOrLessDisplacement =
                    boatTotalDisplacement1 > boatTotalDisplacement2
                        ? "more displacement for"
                        : "less displacement for";

                boatTotalDisplacementDiffPercent =
                    ((boatTotalDisplacement1 - boatTotalDisplacement2) /
                        ((boatTotalDisplacement1 + boatTotalDisplacement2) /
                            2)) *
                    100;
                let summaryTotalDisplacementDiffPercent = Math.abs(
                    boatTotalDisplacementDiffPercent
                ).toFixed(1);

                let moreOrLessPrice = "";

                if (
                    valueIsNotEmpty(Number(boatPrice1.value), "number") &&
                    valueIsNotEmpty(Number(boatPrice2.value), "number") &&
                    valueIsNotEmpty(boatPrice1.value) &&
                    valueIsNotEmpty(boatPrice2.value)
                ) {
                    if (
                        Math.abs(
                            (Number(boatPrice1.value) +
                                Number(boatPrice2.value)) /
                                2
                        ) > 0 &&
                        Number(boatPrice1.value) > 0 &&
                        Number(boatPrice2.value) > 0
                    ) {
                        let boatPriceDiffPercent =
                            ((Number(boatPrice1.value) -
                                Number(boatPrice2.value)) /
                                ((Number(boatPrice1.value) +
                                    Number(boatPrice2.value)) /
                                    2)) *
                            100;
                        summaryPrice =
                            " " +
                            Math.abs(boatPriceDiffPercent).toFixed(1) +
                            "%";
                        moreOrLessPrice =
                            Number(boatPrice1.value) > Number(boatPrice2.value)
                                ? " more cost as entered."
                                : " less cost as entered.";

                        summaryCostRatio = `The resulting Displacement to Cost Ratio is ${Math.abs(
                            boatTotalDisplacementDiffPercent -
                                boatPriceDiffPercent
                        ).toFixed(1)}%.`;
                        summaryCostRatio = `<br /><br class='d-lg-none' /><span class='fst-italic'>${summaryCostRatio}</span>`;
                    } else {
                        moreOrLessDisplacement = moreOrLessDisplacement.replace(
                            " for",
                            "."
                        );
                    }
                } else {
                    moreOrLessDisplacement = moreOrLessDisplacement.replace(
                        " for",
                        "."
                    );
                }

                summaryText = `**The ${boatModel} has ${summaryTotalDisplacementDiffPercent}% ${moreOrLessDisplacement}${summaryPrice}${moreOrLessPrice}${summaryCostRatio} `;

                const boatDiffSummary =
                    document.querySelector("#boatDiffSummary");
                boatDiffSummary.innerHTML = summaryText;
                boatDiffSummary.parentElement.classList.remove("d-none");
            } else {
                const boatDiffSummary =
                    document.querySelector("#boatDiffSummary");
                boatDiffSummary.innerText = "";
                boatDiffSummary.parentElement.classList.add("d-none");
            }
        } else {
            const boatDiffSummary = document.querySelector("#boatDiffSummary");
            boatDiffSummary.innerText = "";
            boatDiffSummary.parentElement.classList.add("d-none");
        }
    }

    /** Swap the selected boats and re-render columns */
    function swapBoats() {
        let newBoatIndex0 = boatIndexes[1];
        let newBoatIndex1 = boatIndexes[0];

        if (
            valueIsNotEmpty(boatIndexes[0]) &&
            valueIsNotEmpty(boatIndexes[1])
        ) {
            getBoatFromDropdown(newBoatIndex0, 1);
            getBoatFromDropdown(newBoatIndex1, 2);
        } else if (valueIsNotEmpty(boatIndexes[0])) {
            getBoatFromDropdown(newBoatIndex1, 2);
            getBoatFromDropdown(null, 1);
        } else if (valueIsNotEmpty(boatIndexes[1])) {
            getBoatFromDropdown(newBoatIndex0, 1);
            getBoatFromDropdown(null, 2);
        } else {
            document.querySelector("#boatSelect1").focus();
        }
    }

    /** Swap the price fields and re-render the price differences */
    function swapPrices() {
        const boatPrice1 = document.querySelector(`#boatPrice1`);
        const boatPrice2 = document.querySelector(`#boatPrice2`);

        if (boatPrice1.value != "" || boatPrice2.value != "") {
            const tempPrice = boatPrice1.value;
            boatPrice1.value = boatPrice2.value;
            boatPrice2.value = tempPrice;

            renderDifferencesPrice();
        }
    }

    /**
     * Checks to see whether the value parameter is not empty
     * @param value the value to check
     * @param type (optional) data type, to check whether the value is the correct type
     * @returns boolean (true or false)
     */
    function valueIsNotEmpty(value, type = "") {
        if (type.toLowerCase() === "number") {
            if (isNaN(value) || typeof value !== "number") {
                return false;
            }
        }

        if (value !== null && value !== undefined && value !== "") {
            return true;
        }
        return false;
    }

    /**
     * Difference Tooltip to display on hover/click of the Delta Symbol
     * @param props component properties
     * @returns React Bootstrap Tooltip
     */
    function DifferenceTooltip(props) {
        return (
            <Tooltip
                id="differenceTooltip"
                {...props}
                className={styles.tooltip}
            >
                Difference
            </Tooltip>
        );
    }

    /**
     * Displacement Modal to display on click of the total displacement table row header/button (in mobile view)
     * @param props component properties
     * @returns React Bootstrap Modal
     */
    function DisplacementModal(props) {
        let header = "Displacement";
        let message = `Attempting to load... If this persists, please contact us.`;

        if (modalMessagesCollection.length > 0) {
            if (valueIsNotEmpty(modalMessagesCollection[2])) {
                header = valueIsNotEmpty(modalMessagesCollection[2].Header)
                    ? modalMessagesCollection[2].Header
                    : header;
                message = valueIsNotEmpty(modalMessagesCollection[2].Message)
                    ? modalMessagesCollection[2].Message
                    : message;
            }
        }

        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="displacementModal"
                centered
                contentClassName={"p-3 " + styles.modal}
            >
                <Modal.Header
                    closeButton
                    className="border-bottom-0 text-center p-2 align-items-start flex-column-reverse"
                >
                    <Modal.Title
                        className="w-100 fst-italic fw-bold"
                        id="displacementModal"
                    >
                        {header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <p className="mb-0">{message}</p>
                </Modal.Body>
            </Modal>
        );
    }

    /**
     * Summary Modal to display on click of the difference summary text
     * @param props component properties
     * @returns React Bootstrap Modal
     */
    function SummaryModal(props) {
        let header = "Displacement to Cost Ratio";
        let message = `Attempting to load... If this persists, <Link>please contact us.</Link>`;

        if (modalMessagesCollection.length > 0) {
            if (valueIsNotEmpty(modalMessagesCollection[0])) {
                header = valueIsNotEmpty(modalMessagesCollection[0].Header)
                    ? modalMessagesCollection[0].Header
                    : header;
                message = valueIsNotEmpty(modalMessagesCollection[0].Message)
                    ? modalMessagesCollection[0].Message
                    : message;
            }
        }

        let messageStart = "";
        let linkText = "";
        let messageEnd = "";
        let hideLink = "";

        let messageSplit = message.split("<Link>");
        messageStart = messageSplit[0];

        if (messageSplit.length >= 2) {
            let messageSplit2 = messageSplit[1].split("</Link>");

            linkText = messageSplit2[0];
            if (messageSplit2.length >= 2) {
                messageEnd = messageSplit2[1];
            }
            hideLink = "";
        } else {
            hideLink = "d-none";
        }

        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="summaryModal"
                centered
                contentClassName={"p-3 " + styles.modal}
            >
                <Modal.Header
                    closeButton
                    className="border-bottom-0 text-center p-2 align-items-start flex-column-reverse"
                >
                    <Modal.Title
                        className="w-100 fst-italic fw-bold"
                        id="summaryModal"
                    >
                        {header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <p className="mb-0">
                        {messageStart}
                        <Link
                            to="/contact"
                            className={
                                "fst-italic " +
                                styles.boatComparisonLink +
                                " " +
                                hideLink
                            }
                        >
                            {linkText}
                        </Link>
                        {messageEnd}
                    </p>
                </Modal.Body>
            </Modal>
        );
    }

    /**
     * Difference Percentage Modal to display on click of the difference percent table row header/button (in mobile view)
     * @param props component properties
     * @returns React Bootstrap Modal
     */
    function DifferencePercentageModal(props) {
        let header = "% Difference";
        let message = `Attempting to load... If this persists, please contact us.`;

        if (modalMessagesCollection.length > 0) {
            if (valueIsNotEmpty(modalMessagesCollection[1])) {
                header = valueIsNotEmpty(modalMessagesCollection[1].Header)
                    ? modalMessagesCollection[1].Header
                    : header;
                message = valueIsNotEmpty(modalMessagesCollection[1].Message)
                    ? modalMessagesCollection[1].Message
                    : message;
            }
        }

        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="differencePercentageModal"
                centered
                contentClassName={"p-3 " + styles.modal}
            >
                <Modal.Header
                    closeButton
                    className="border-bottom-0 text-center p-2 align-items-start flex-column-reverse"
                >
                    <Modal.Title
                        className="w-100 fst-italic fw-bold"
                        id="differencePercentageModal"
                    >
                        {header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <p className="mb-0">{message}</p>
                </Modal.Body>
            </Modal>
        );
    }

    /**
     * Toggle visibility of table row data in mobile view
     * @param whichRow the element to show/hide
     * @param hasDifference (default: false) boolean indicator of whether the row contains a difference value
     */
    function toggleMobileRow(whichRow, hasDifference = false) {
        const row = document.querySelector(whichRow);
        const caretUp = document.querySelector(
            whichRow.replace("Row", "CaretUp")
        );
        const caretDown = document.querySelector(
            whichRow.replace("Row", "CaretDown")
        );

        if (valueIsNotEmpty(caretUp)) {
            caretUp.classList.toggle("d-none");
        }
        if (valueIsNotEmpty(caretDown)) {
            caretDown.classList.toggle("d-none");
        }

        if (valueIsNotEmpty(row)) {
            row.classList.toggle("d-none");
            if (!hasDifference) {
                row.classList.toggle("d-lg-table-row");
            }
        }
    }

    /**
     * Toggle thick borders around table row data in mobile view
     * @param rowTop top row element
     * @param rowBottom bottom row element
     * @param noDifference (default: false) boolean indicator of whether the row contains a difference value
     */
    function toggleMobileRowThickBorders(
        rowTop,
        rowBottom,
        noDifference = false
    ) {
        if (valueIsNotEmpty(rowTop)) {
            if (valueIsNotEmpty(document.querySelector(rowTop))) {
                document
                    .querySelector(rowTop)
                    .classList.toggle(styles.borderThickTop);
            }
        }
        if (valueIsNotEmpty(rowBottom)) {
            if (valueIsNotEmpty(document.querySelector(rowBottom))) {
                if (noDifference) {
                    document
                        .querySelector(rowBottom)
                        .classList.toggle(styles.borderThickNoDiffBottom);
                } else {
                    document
                        .querySelector(rowBottom)
                        .classList.toggle(styles.borderThickBottom);
                }
            }
        }
    }

    // Chart rendering
    async function renderChart(boatOne, boatTwo) {
        let boatOnePrice;
        let boatTwoPrice;
        let boatOneFullName;
        let boatTwoFullName;
        let footer;
        let config;

        let boatChartElement = document.querySelector("#boat-chart");

        if (boatOne && boatTwo) {
            boatOneFullName = boatOne.Manufacturer + " " + boatOne.Model;
            boatTwoFullName = boatTwo.Manufacturer + " " + boatTwo.Model;
            // if the graph needs to be updated, rather than drawn
            if (graphIsDrawn) {
                //updating labels
                myChart.options.plugins.title.text =
                    boatOneFullName + " vs. " + boatTwoFullName;
                labels[0] = boatOneFullName;
                labels[1] = boatTwoFullName;

                //updating the dry weight
                myChart.data.datasets[0].data[0] = boatOne.DryWeight;
                myChart.data.datasets[0].data[1] = boatTwo.DryWeight;

                //updating the max stock ballast
                myChart.data.datasets[1].data[0] = boatOne.MaxStockBallast;
                myChart.data.datasets[1].data[1] = boatTwo.MaxStockBallast;

                //updating the weight capacity
                myChart.data.datasets[2].data[0] = boatOne.WeightCapacity;
                myChart.data.datasets[2].data[1] = boatTwo.WeightCapacity;

                boatOnePrice =
                    "$" + document.querySelector("#boatPrice1").value;
                boatTwoPrice =
                    "$" + document.querySelector("#boatPrice2").value;

                footer = (tooltipItems) => {
                    boatOnePrice =
                        "$" + document.querySelector("#boatPrice1").value;
                    boatTwoPrice =
                        "$" + document.querySelector("#boatPrice2").value;

                    let boatOneFullPrice = "Price: " + boatOnePrice;
                    let boatTwoFullPrice = "Price: " + boatTwoPrice;
                    if (
                        tooltipItems[0].label == boatOneFullName ||
                        tooltipItems[0] == boatTwoFullName
                    ) {
                        return boatOneFullPrice;
                    } else {
                        return boatTwoFullPrice;
                    }
                };

                myChart.options = {
                    plugins: {
                        title: {
                            display: true,
                            text: boatOneFullName + " vs. " + boatTwoFullName,
                        },
                        tooltip: {
                            callbacks: {
                                footer: footer,
                            },
                        },
                    },
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            id: "Weight/Displacement",
                            stacked: true,
                            beginAtZero: true,
                            display: true,
                            position: "left",
                        },
                    },
                };

                myChart.update();

                // first time drawing graph
            } else {
                labels = [boatOneFullName, boatTwoFullName];
                data = {
                    labels: labels,
                    datasets: [
                        {
                            label: "Dry Weight (lbs)",
                            data: [boatOne.DryWeight, boatTwo.DryWeight],
                            backgroundColor: "rgba(79, 78, 77)",
                            borderColor: "rgb(79, 78, 77)",
                            borderWidth: 1,
                        },
                        {
                            label: "Max Stock Ballast (lbs)",
                            data: [
                                boatOne.MaxStockBallast,
                                boatTwo.MaxStockBallast,
                            ],
                            backgroundColor: "rgba(28, 229, 255)",
                            borderColor: "rgb(28, 229, 255)",
                            borderWidth: 1,
                        },
                        {
                            label: "Max of Weight Capacity (lbs)",
                            data: [
                                boatOne.WeightCapacity,
                                boatTwo.WeightCapacity,
                            ],
                            backgroundColor: "rgba(250, 104, 0)",
                            borderColor: "rgb(250, 104, 0)",
                            borderWidth: 1,
                        },
                    ],
                };

                footer = (tooltipItems) => {
                    boatOnePrice =
                        "$" + document.querySelector("#boatPrice1").value;
                    boatTwoPrice =
                        "$" + document.querySelector("#boatPrice2").value;

                    let boatOneFullPrice = "Price: " + boatOnePrice;
                    let boatTwoFullPrice = "Price: " + boatTwoPrice;
                    if (
                        tooltipItems[0].label == boatOneFullName ||
                        tooltipItems[0] == boatTwoFullName
                    ) {
                        return boatOneFullPrice;
                    } else {
                        return boatTwoFullPrice;
                    }
                };

                config = {
                    type: "bar",
                    data: data,
                    options: {
                        plugins: {
                            title: {
                                display: true,
                                text:
                                    boatOneFullName + " vs. " + boatTwoFullName,
                            },
                            tooltip: {
                                callbacks: {
                                    footer: footer,
                                },
                            },
                        },
                        responsive: true,
                        scales: {
                            x: {
                                stacked: true,
                            },
                            y: {
                                id: "Weight/Displacement",
                                stacked: true,
                                beginAtZero: true,
                                display: true,
                                position: "left",
                            },
                        },
                    },
                };
                myChart = new Chart(boatChartElement, config);
                document
                    .querySelector("#boatChartContainer")
                    .classList.remove("d-none");
                setGraphIsDrawn(true);
            }
        }
    }

    return (
        <main className="max-container mx-auto">
            <Container fluid>
                <div className="mt-5">
                    <h1 className="text-center mb-3">COMPARE BOATS</h1>
                    {/* Boat Selection Dropdowns & Swap Button */}
                    <div className="d-flex align-items-center justify-content-center flex-column flex-md-row">
                        <div className="col-12 col-sm-10 col-md-4 col-lg-3">
                            <BoatSelect
                                boatCollection={processedBoatCollection}
                                idNum={1}
                                getTypeaheadInput={(input) => {
                                    if (boatSelectInputs[0] === null) {
                                        let newBoatSelectInputs =
                                            boatSelectInputs;
                                        newBoatSelectInputs[0] = input;
                                        setBoatSelectInputs(
                                            newBoatSelectInputs
                                        );
                                    }
                                }}
                                onChangeEvent={(index, idNum) => {
                                    clearPrice(idNum);
                                    getBoatFromDropdown(index, idNum);
                                    renderChart(
                                        boatCollection[boatIndexes[0]],
                                        boatCollection[boatIndexes[1]]
                                    );
                                }}
                                tabIndex={"1"}
                                inputClassName={
                                    styles.boatSelect + " px-4 py-2"
                                }
                                className={styles.boatSelectTypeahead}
                            />
                        </div>
                        <div className="mx-md-3 my-3 my-md-0">
                            <Button
                                className={
                                    "bg-transparent border-0 py-1 " +
                                    styles.btnSwap
                                }
                                tabIndex="5"
                                onClick={() => {
                                    swapBoats();
                                    swapPrices();
                                    renderChart(
                                        boatCollection[boatIndexes[0]],
                                        boatCollection[boatIndexes[1]]
                                    );
                                }}
                            >
                                <RepeatIcon
                                    color="#33b1ff"
                                    width="3.5rem"
                                    height="3.5rem"
                                    className="d-lg-none"
                                />
                                <RepeatIcon
                                    color="#33b1ff"
                                    width="2.8rem"
                                    height="2.8rem"
                                    className="d-none d-lg-inline-block"
                                />
                            </Button>
                        </div>
                        <div className="col-12 col-sm-10 col-md-4 col-lg-3">
                            <BoatSelect
                                boatCollection={processedBoatCollection}
                                idNum={2}
                                getTypeaheadInput={(input) => {
                                    if (boatSelectInputs[1] === null) {
                                        let newBoatSelectInputs =
                                            boatSelectInputs;
                                        newBoatSelectInputs[1] = input;
                                        setBoatSelectInputs(
                                            newBoatSelectInputs
                                        );
                                    }
                                }}
                                onChangeEvent={(index, idNum) => {
                                    clearPrice(idNum);
                                    getBoatFromDropdown(index, idNum);
                                    renderChart(
                                        boatCollection[boatIndexes[0]],
                                        boatCollection[boatIndexes[1]]
                                    );
                                }}
                                tabIndex={"2"}
                                inputClassName={
                                    styles.boatSelect + " px-4 py-2"
                                }
                                className={styles.boatSelectTypeahead}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-md-3 mt-4">
                    <Row className="align-items-lg-end">
                        <div className="col-12 col-lg-10">
                            <div
                                className={
                                    "card mb-3 " + styles.boatComparisonTable
                                }
                            >
                                {/* Main Comparison Table */}
                                <Table
                                    id="boatComparisonTable"
                                    className="mb-0"
                                    bordered
                                >
                                    <tbody className="text-center align-middle">
                                        {/* Model - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none fw-bold fs-2 text-uppercase border-top-0 " +
                                                styles.pointer
                                            }
                                            id="modelHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow("#modelRow");

                                                toggleMobileRowThickBorders(
                                                    "",
                                                    "#manufacturerHeaderMobileRow",
                                                    true
                                                );
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <TableHeaderCaret
                                                    header="Model"
                                                    id="model"
                                                    upCaretClassName=""
                                                    downCaretClassName="d-none"
                                                />
                                            </th>
                                        </tr>
                                        {/* Model - Desktop */}
                                        <tr
                                            className="fw-bold fs-2 text-uppercase border-top-0"
                                            id="modelRow"
                                        >
                                            <th className="d-none d-lg-table-cell border-start-0">
                                                Model
                                            </th>
                                            <td
                                                id="boatModel1"
                                                className={
                                                    "boat-model-1 text-white border-start-0 " +
                                                    styles.boatModel1
                                                }
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatModel2"
                                                className={
                                                    "text-white border-end-0 " +
                                                    styles.boatModel2
                                                }
                                            >
                                                --
                                            </td>
                                            <OverlayTrigger
                                                placement="top-end"
                                                overlay={DifferenceTooltip}
                                                show={
                                                    differencesTooltipHoverShow
                                                }
                                            >
                                                <td
                                                    id="boatModelDiff"
                                                    className="fs-2 d-none d-lg-table-cell border-end-0 px-3"
                                                    onMouseEnter={() => {
                                                        setDifferencesTooltipHoverShow(
                                                            true
                                                        );
                                                    }}
                                                    onMouseLeave={() => {
                                                        setDifferencesTooltipHoverShow(
                                                            false
                                                        );
                                                    }}
                                                >
                                                    <DeltaIcon
                                                        color="#394955"
                                                        width="1.8rem"
                                                        height="1.8rem"
                                                    ></DeltaIcon>
                                                </td>
                                            </OverlayTrigger>
                                        </tr>
                                        {/* Manufacturer - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none " +
                                                styles.pointer +
                                                " " +
                                                styles.borderThickNoDiffBottom
                                            }
                                            id="manufacturerHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow(
                                                    "#manufacturerRow"
                                                );

                                                toggleMobileRowThickBorders(
                                                    "#manufacturerHeaderMobileRow",
                                                    "#yearHeaderMobileRow",
                                                    true
                                                );
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0 py-3"
                                            >
                                                <TableHeaderCaret
                                                    header="Manufacturer"
                                                    id="manufacturer"
                                                />
                                            </th>
                                        </tr>
                                        {/* Manufacturer - Desktop */}
                                        <tr
                                            className="text-nowrap d-none d-lg-table-row"
                                            id="manufacturerRow"
                                        >
                                            <th className="d-none d-lg-table-cell border-start-0">
                                                Manufacturer
                                            </th>
                                            <td
                                                id="boatManufacturer1"
                                                className="border-start-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatManufacturer2"
                                                className="border-end-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatManufacturerDiff"
                                                className="fst-italic d-none d-lg-table-cell border-end-0"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Year - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none " + styles.pointer
                                            }
                                            id="yearHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow("#yearRow");

                                                toggleMobileRowThickBorders(
                                                    "#yearHeaderMobileRow",
                                                    "#lengthHeaderMobileRow",
                                                    true
                                                );
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0 py-3"
                                            >
                                                <TableHeaderCaret
                                                    header="Year"
                                                    id="year"
                                                />
                                            </th>
                                        </tr>
                                        {/* Year - Desktop*/}
                                        <tr
                                            className="text-nowrap d-none d-lg-table-row"
                                            id="yearRow"
                                        >
                                            <th className="d-none d-lg-table-cell border-start-0">
                                                Year
                                            </th>
                                            <td
                                                id="boatYear1"
                                                className="border-start-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatYear2"
                                                className="border-end-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatYearDiff"
                                                className="fst-italic d-none d-lg-table-cell border-end-0"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Length - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none " + styles.pointer
                                            }
                                            id="lengthHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow("#lengthRow");
                                                toggleMobileRow(
                                                    "#lengthDiffMobileRow"
                                                );

                                                toggleMobileRowThickBorders(
                                                    "#lengthHeaderMobileRow",
                                                    "#lengthDiffMobileRow"
                                                );
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0 py-3"
                                            >
                                                <TableHeaderCaret
                                                    header="Length"
                                                    id="length"
                                                />
                                            </th>
                                        </tr>
                                        {/* Length - Desktop*/}
                                        <tr
                                            className="text-nowrap d-none d-lg-table-row"
                                            id="lengthRow"
                                        >
                                            <th className="d-none d-lg-table-cell border-start-0">
                                                Length
                                            </th>
                                            <td
                                                id="boatLength1"
                                                className="border-start-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatLength2"
                                                className="border-end-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatLengthDiff"
                                                className="fst-italic d-none d-lg-table-cell border-end-0"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Length - Mobile */}
                                        <tr
                                            className="d-none d-lg-none"
                                            id="lengthDiffMobileRow"
                                        >
                                            <td
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <span>Difference: </span>
                                                <span id="boatLengthDiffMobile">
                                                    --
                                                </span>
                                            </td>
                                        </tr>
                                        {/* Seating Capacity - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none " + styles.pointer
                                            }
                                            id="seatingCapacityHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow(
                                                    "#seatingCapacityRow"
                                                );
                                                toggleMobileRow(
                                                    "#seatingCapacityDiffMobileRow"
                                                );

                                                toggleMobileRowThickBorders(
                                                    "#seatingCapacityHeaderMobileRow",
                                                    "#seatingCapacityDiffMobileRow"
                                                );
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0 py-3"
                                            >
                                                <TableHeaderCaret
                                                    header="Seating"
                                                    id="seatingCapacity"
                                                />
                                            </th>
                                        </tr>
                                        {/* Seating Capacity - Desktop*/}
                                        <tr
                                            className="text-nowrap d-none d-lg-table-row"
                                            id="seatingCapacityRow"
                                        >
                                            <th className="d-none d-lg-table-cell border-start-0">
                                                Seating
                                            </th>
                                            <td
                                                id="boatSeatingCapacity1"
                                                className="border-start-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatSeatingCapacity2"
                                                className="border-end-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatSeatingCapacityDiff"
                                                className="fst-italic d-none d-lg-table-cell border-end-0"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Seating Capacity - Mobile */}
                                        <tr
                                            className="d-none d-lg-none"
                                            id="seatingCapacityDiffMobileRow"
                                        >
                                            <td
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <span>Difference: </span>
                                                <span id="boatSeatingCapacityDiffMobile">
                                                    --
                                                </span>
                                            </td>
                                        </tr>
                                        {/* Dry Weight - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none " + styles.pointer
                                            }
                                            id="dryWeightHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow(
                                                    "#dryWeightRow"
                                                );
                                                toggleMobileRow(
                                                    "#dryWeightDiffMobileRow"
                                                );

                                                toggleMobileRowThickBorders(
                                                    "#dryWeightHeaderMobileRow",
                                                    "#dryWeightDiffMobileRow"
                                                );
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0 py-3"
                                            >
                                                <TableHeaderCaret
                                                    header="Dry Weight"
                                                    id="dryWeight"
                                                />
                                            </th>
                                        </tr>
                                        {/* Dry Weight - Desktop*/}
                                        <tr
                                            className="text-nowrap d-none d-lg-table-row"
                                            id="dryWeightRow"
                                        >
                                            <th className="d-none d-lg-table-cell border-start-0">
                                                Dry Weight
                                            </th>
                                            <td
                                                id="boatDryWeight1"
                                                className="border-start-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatDryWeight2"
                                                className="border-end-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatDryWeightDiff"
                                                className="fst-italic d-none d-lg-table-cell border-end-0"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Dry Weight - Mobile */}
                                        <tr
                                            className="d-none d-lg-none"
                                            id="dryWeightDiffMobileRow"
                                        >
                                            <td
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <span>Difference: </span>
                                                <span id="boatDryWeightDiffMobile">
                                                    --
                                                </span>
                                            </td>
                                        </tr>
                                        {/* MAX Stock Ballast - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none " + styles.pointer
                                            }
                                            id="maxStockBallastHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow(
                                                    "#maxStockBallastRow"
                                                );
                                                toggleMobileRow(
                                                    "#maxStockBallastDiffMobileRow"
                                                );

                                                toggleMobileRowThickBorders(
                                                    "#maxStockBallastHeaderMobileRow",
                                                    "#maxStockBallastDiffMobileRow"
                                                );
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0 py-3"
                                            >
                                                <TableHeaderCaret
                                                    header="MAX Stock Ballast"
                                                    id="maxStockBallast"
                                                />
                                            </th>
                                        </tr>
                                        {/* MAX Stock Ballast - Desktop*/}
                                        <tr
                                            className="text-nowrap d-none d-lg-table-row"
                                            id="maxStockBallastRow"
                                        >
                                            <th className="d-none d-lg-table-cell border-start-0">
                                                MAX Stock Ballast
                                            </th>
                                            <td
                                                id="boatMaxStockBallast1"
                                                className="border-start-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatMaxStockBallast2"
                                                className="border-end-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatMaxStockBallastDiff"
                                                className="fst-italic d-none d-lg-table-cell border-end-0"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* MAX Stock Ballast - Mobile */}
                                        <tr
                                            className="d-none d-lg-none"
                                            id="maxStockBallastDiffMobileRow"
                                        >
                                            <td
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <span>Difference: </span>
                                                <span id="boatMaxStockBallastDiffMobile">
                                                    --
                                                </span>
                                            </td>
                                        </tr>
                                        {/* Displacement - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none " + styles.pointer
                                            }
                                            id="displacementHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow(
                                                    "#displacementRow"
                                                );
                                                toggleMobileRow(
                                                    "#displacementDiffMobileRow"
                                                );

                                                toggleMobileRowThickBorders(
                                                    "#displacementHeaderMobileRow",
                                                    "#displacementDiffMobileRow"
                                                );
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <TableHeaderCaret
                                                    header="Displacement"
                                                    id="displacement"
                                                >
                                                    <br />
                                                    <span className="fst-italic fw-normal">
                                                        (before crew/gas/gear)
                                                    </span>
                                                </TableHeaderCaret>
                                            </th>
                                        </tr>
                                        {/* Displacement - Desktop*/}
                                        <tr
                                            className="text-nowrap d-none d-lg-table-row"
                                            id="displacementRow"
                                        >
                                            <th className="d-none d-lg-table-cell border-start-0">
                                                Displacement
                                                <br />
                                                <span className="fst-italic fw-normal">
                                                    (before crew/gas/gear)
                                                </span>
                                            </th>
                                            <td
                                                id="boatDisplacement1"
                                                className="border-start-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatDisplacement2"
                                                className="border-end-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatDisplacementDiff"
                                                className="fst-italic d-none d-lg-table-cell border-end-0"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Displacement - Mobile */}
                                        <tr
                                            className="d-none d-lg-none"
                                            id="displacementDiffMobileRow"
                                        >
                                            <td
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <span>Difference: </span>
                                                <span id="boatDisplacementDiffMobile">
                                                    --
                                                </span>
                                            </td>
                                        </tr>
                                        {/* Weight Capacity - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none " + styles.pointer
                                            }
                                            id="weightCapacityHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow(
                                                    "#weightCapacityRow"
                                                );
                                                toggleMobileRow(
                                                    "#weightCapacityDiffMobileRow"
                                                );

                                                toggleMobileRowThickBorders(
                                                    "#weightCapacityHeaderMobileRow",
                                                    "#weightCapacityDiffMobileRow"
                                                );
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0 py-3"
                                            >
                                                <TableHeaderCaret
                                                    header="Weight Capacity"
                                                    id="weightCapacity"
                                                />
                                            </th>
                                        </tr>
                                        {/* Weight Capacity - Desktop*/}
                                        <tr
                                            className="text-nowrap d-none d-lg-table-row"
                                            id="weightCapacityRow"
                                        >
                                            <th className="d-none d-lg-table-cell border-start-0">
                                                Weight Capacity
                                            </th>
                                            <td
                                                id="boatWeightCapacity1"
                                                className="border-start-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatWeightCapacity2"
                                                className="border-end-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatWeightCapacityDiff"
                                                className="fst-italic d-none d-lg-table-cell border-end-0"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Weight Capacity - Mobile */}
                                        <tr
                                            className="d-none d-lg-none"
                                            id="weightCapacityDiffMobileRow"
                                        >
                                            <td
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <span>Difference: </span>
                                                <span id="boatWeightCapacityDiffMobile">
                                                    --
                                                </span>
                                            </td>
                                        </tr>
                                        {/* Total Displacement - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none " + styles.pointer
                                            }
                                            id="totalDisplacementHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow(
                                                    "#totalDisplacementRow"
                                                );
                                                toggleMobileRow(
                                                    "#totalDisplacementDiffMobileRow"
                                                );

                                                toggleMobileRowThickBorders(
                                                    "#totalDisplacementHeaderMobileRow",
                                                    "#totalDisplacementDiffMobileRow"
                                                );
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0 py-3"
                                            >
                                                <TableHeaderCaret
                                                    header="Total Displacement"
                                                    id="totalDisplacement"
                                                />
                                            </th>
                                        </tr>
                                        {/* Total Displacement - Desktop*/}
                                        <tr
                                            className="text-nowrap d-none d-lg-table-row"
                                            id="totalDisplacementRow"
                                        >
                                            <th
                                                className={
                                                    "d-none d-lg-table-cell border-start-0 " +
                                                    styles.pointer +
                                                    " " +
                                                    styles.btnModal
                                                }
                                                onClick={() => {
                                                    setDisplacementModalShow(
                                                        true
                                                    );
                                                }}
                                            >
                                                Total Displacement{" "}
                                                <QuestionCircleFill color="#999" />
                                            </th>
                                            <td
                                                id="boatTotalDisplacement1"
                                                className="border-start-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatTotalDisplacement2"
                                                className="border-end-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatTotalDisplacementDiff"
                                                className="fst-italic d-none d-lg-table-cell border-end-0"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Total Displacement - Mobile */}
                                        <tr
                                            className="d-none d-lg-none"
                                            id="totalDisplacementDiffMobileRow"
                                        >
                                            <td
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <span>Difference: </span>
                                                <span id="boatTotalDisplacementDiffMobile">
                                                    --
                                                </span>{" "}
                                                <span className="fst-italic">
                                                    (
                                                    <span id="boatTotalDisplacementDiffPercentMobile">
                                                        --
                                                    </span>
                                                    )
                                                </span>
                                            </td>
                                        </tr>
                                        <DisplacementModal
                                            show={displacementModalShow}
                                            onHide={() => {
                                                setDisplacementModalShow(false);
                                            }}
                                        ></DisplacementModal>

                                        {/* Displacement / ft - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none " + styles.pointer
                                            }
                                            id="dfootHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow("#dfootRow");
                                                toggleMobileRow(
                                                    "#dfootDiffMobileRow"
                                                );
                                                toggleMobileRowThickBorders(
                                                    "#dfootHeaderMobileRow",
                                                    "#dfootDiffMobileRow"
                                                );
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0 py-3"
                                            >
                                                <TableHeaderCaret
                                                    header="Displacement / ft"
                                                    id="dfoot"
                                                />
                                            </th>
                                        </tr>
                                        {/* Displacement / ft - Desktop*/}
                                        <tr
                                            className="text-nowrap d-none d-lg-table-row"
                                            id="dfootRow"
                                        >
                                            <th className="d-none d-lg-table-cell border-start-0">
                                                Displacement / ft
                                            </th>
                                            <td
                                                id="boatDfoot1"
                                                className="border-start-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                id="boatDfoot2"
                                                className="border-end-0"
                                            >
                                                --
                                            </td>
                                            <td
                                                className="fst-italic d-none d-lg-table-cell border-end-0"
                                                id="boatDfootDiff"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Displacement / ft - Mobile */}
                                        <tr
                                            className="d-none d-lg-none"
                                            id="dfootDiffMobileRow"
                                        >
                                            <td
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <span>Difference: </span>
                                                <span id="boatDfootDiffMobile">
                                                    --
                                                </span>{" "}
                                                <span className="fst-italic">
                                                    (
                                                    <span id="boatDfootDiffPercentMobile">
                                                        --
                                                    </span>
                                                    )
                                                </span>
                                            </td>
                                        </tr>

                                        {/* Price - Mobile */}
                                        <tr
                                            className={
                                                "d-lg-none fw-bold fs-5 border-bottom-0 " +
                                                styles.pointer
                                            }
                                            id="priceHeaderMobileRow"
                                            onClick={() => {
                                                toggleMobileRow("#priceRow");
                                                toggleMobileRow(
                                                    "#priceDiffMobileRow"
                                                );

                                                toggleMobileRowThickBorders(
                                                    "#priceHeaderMobileRow",
                                                    ""
                                                );

                                                document
                                                    .querySelectorAll(
                                                        "." +
                                                            styles.inputGroupFocus
                                                    )
                                                    .forEach((inputGroup) => {
                                                        inputGroup.classList.toggle(
                                                            "mb-2"
                                                        );
                                                        inputGroup.classList.toggle(
                                                            "mb-lg-0"
                                                        );
                                                    });
                                            }}
                                        >
                                            <th
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <TableHeaderCaret id="price">
                                                    <span className="d-flex flex-column">
                                                        Price
                                                        <span className="fst-italic fw-normal fs-6">
                                                            (enter a price)
                                                        </span>
                                                    </span>
                                                </TableHeaderCaret>
                                            </th>
                                        </tr>
                                        {/* Price - Desktop*/}
                                        <tr className="fw-bold fs-5 text-nowrap border-bottom-0">
                                            <th className="d-none d-lg-table-cell border-start-0">
                                                <div className="d-flex flex-column">
                                                    {/**
                                                     * @see https://getbootstrap.com/docs/5.0/utilities/spacing/#negative-margin
                                                     */}
                                                    <span
                                                        className={styles.mtN1}
                                                    >
                                                        Price
                                                    </span>
                                                    <span
                                                        className={
                                                            "fst-italic fw-normal fs-6 " +
                                                            styles.mtN1
                                                        }
                                                    >
                                                        (enter a price)
                                                    </span>
                                                </div>
                                            </th>
                                            <td
                                                className="border-start-0"
                                                onClick={() => {
                                                    document
                                                        .querySelector(
                                                            "#boatPrice1"
                                                        )
                                                        .focus();
                                                }}
                                            >
                                                <InputGroup
                                                    className={
                                                        styles.inputGroupFocus +
                                                        " rounded mb-2 mb-lg-0"
                                                    }
                                                >
                                                    <InputGroup.Text
                                                        className={
                                                            "border-0 bg-transparent fw-bold fs-5 " +
                                                            styles.inputGroupText
                                                        }
                                                    >
                                                        $
                                                    </InputGroup.Text>
                                                    <FormControl
                                                        size="lg"
                                                        id="boatPrice1"
                                                        type="text"
                                                        placeholder="--"
                                                        tabIndex="3"
                                                        className={
                                                            "text-center border-0 ps-0 ps-lg-3 fw-bold " +
                                                            styles.inputRemoveFocus +
                                                            " " +
                                                            styles.boatCol1
                                                        }
                                                        maxLength="6"
                                                        autoComplete="off"
                                                        onChange={() => {
                                                            renderDifferencesPrice();
                                                            renderChart(
                                                                boatCollection[
                                                                    boatIndexes[0]
                                                                ],
                                                                boatCollection[
                                                                    boatIndexes[1]
                                                                ]
                                                            );
                                                        }}
                                                    ></FormControl>
                                                    <InputGroup.Text className="border-0 p-0 d-lg-none rounded-end pe-1 bg-transparent"></InputGroup.Text>
                                                    <InputGroup.Text className="border-0 bg-transparent fw-bold fs-5 d-none d-lg-flex">
                                                        <span className="invisible">
                                                            $
                                                        </span>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </td>
                                            <td
                                                className="border-end-0"
                                                onClick={() => {
                                                    document
                                                        .querySelector(
                                                            "#boatPrice2"
                                                        )
                                                        .focus();
                                                }}
                                            >
                                                <InputGroup
                                                    className={
                                                        styles.inputGroupFocus +
                                                        " rounded mb-2 mb-lg-0"
                                                    }
                                                >
                                                    <InputGroup.Text
                                                        className={
                                                            "border-0 bg-transparent fw-bold fs-5 " +
                                                            styles.inputGroupText
                                                        }
                                                    >
                                                        $
                                                    </InputGroup.Text>
                                                    <FormControl
                                                        size="lg"
                                                        id="boatPrice2"
                                                        type="text"
                                                        placeholder="--"
                                                        tabIndex="4"
                                                        className={
                                                            "text-center border-0 ps-0 ps-lg-3 fw-bold " +
                                                            styles.inputRemoveFocus +
                                                            " " +
                                                            styles.boatCol2
                                                        }
                                                        maxLength="6"
                                                        autoComplete="off"
                                                        onChange={() => {
                                                            renderDifferencesPrice();
                                                            renderChart(
                                                                boatCollection[
                                                                    boatIndexes[0]
                                                                ],
                                                                boatCollection[
                                                                    boatIndexes[1]
                                                                ]
                                                            );
                                                        }}
                                                    ></FormControl>
                                                    <InputGroup.Text className="border-0 p-0 d-lg-none rounded-end pe-1 bg-transparent"></InputGroup.Text>
                                                    <InputGroup.Text className="border-0 bg-transparent fw-bold fs-5 d-none d-lg-flex">
                                                        <span className="invisible">
                                                            $
                                                        </span>
                                                    </InputGroup.Text>
                                                </InputGroup>
                                            </td>
                                            <td
                                                className="fst-italic d-none d-lg-table-cell border-end-0"
                                                id="boatPriceDiff"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Price - Mobile */}
                                        <tr
                                            className="d-none d-lg-none border-bottom-0"
                                            id="priceDiffMobileRow"
                                        >
                                            <td
                                                colSpan={2}
                                                className="border-start-0 border-end-0"
                                            >
                                                <span>Difference: </span>
                                                <span id="boatPriceDiffMobile">
                                                    --
                                                </span>{" "}
                                                <span className="fst-italic">
                                                    (
                                                    <span id="boatPriceDiffPercentMobile">
                                                        --
                                                    </span>
                                                    )
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        {/* Percentage Difference Table */}
                        <div className="col-12 col-lg-2 d-none d-lg-block">
                            <Table className="mb-0">
                                <tbody className="text-center align-middle fw-bold">
                                    <tr className="border-0">
                                        <th
                                            className={
                                                "text-uppercase border-0 " +
                                                styles.textMain +
                                                " " +
                                                styles.pointer +
                                                " " +
                                                styles.btnModal
                                            }
                                            onClick={() => {
                                                setDifferencePercentageModalShow(
                                                    true
                                                );
                                            }}
                                        >
                                            Difference{" "}
                                            <QuestionCircleFill color="#999" />
                                        </th>
                                    </tr>
                                </tbody>
                            </Table>
                            <div
                                className={
                                    "card mb-3 " +
                                    styles.boatDifferencePercentageTable
                                }
                            >
                                <Table
                                    id="boatComparisonPercentDifferenceTable"
                                    className="mb-0"
                                    bordered
                                >
                                    <tbody className="text-center align-middle fw-bold">
                                        {/* Total Displacement % - Desktop*/}
                                        <tr className="border-top-0">
                                            <td
                                                id="boatTotalDisplacementDiffPercent"
                                                className="border-start-0 border-end-0"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Displacement / ft % - Desktop*/}
                                        <tr>
                                            <td
                                                id="boatDfootDiffPercent"
                                                className="border-start-0 border-end-0"
                                            >
                                                --
                                            </td>
                                        </tr>
                                        {/* Price % - Desktop*/}
                                        <tr className="border-bottom-0">
                                            <td className="border-start-0 border-end-0">
                                                <FormControl
                                                    size="lg"
                                                    id="boatPriceDiffPercent"
                                                    className={
                                                        "text-center form-control-plaintext bg-transparent fw-bold " +
                                                        styles.removeFocus +
                                                        " " +
                                                        styles.textMain
                                                    }
                                                    defaultValue="--"
                                                    tabIndex="-1"
                                                    readOnly
                                                ></FormControl>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <DifferencePercentageModal
                            show={differencePercentageModalShow}
                            onHide={() => {
                                setDifferencePercentageModalShow(false);
                            }}
                        ></DifferencePercentageModal>
                        <div className="text-center">
                            {/* Difference Summary */}
                            <Button
                                className={
                                    "bg-transparent border-0 d-none mb-3 " +
                                    styles.textMain +
                                    " " +
                                    styles.btnModal
                                }
                                onClick={() => {
                                    setSummaryModalShow(true);
                                }}
                                id="boatDiffSummaryButton"
                            >
                                <span id="boatDiffSummary"></span>
                                <QuestionCircleFill color="#999" />
                            </Button>
                            <SummaryModal
                                show={summaryModalShow}
                                onHide={() => {
                                    setSummaryModalShow(false);
                                }}
                            ></SummaryModal>

                            <div className="d-flex d-lg-none mx-auto">
                                {/* Total Displacement Information Button (mobile view only) */}
                                <div className="col-6 pe-2 text-end">
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => {
                                            setDisplacementModalShow(true);
                                        }}
                                        className={
                                            "mb-3 text-nowrap " +
                                            styles.btnMainOutline
                                        }
                                    >
                                        Displacement{" "}
                                        <QuestionCircleFill color="#999" />
                                    </Button>
                                </div>
                                {/* Difference Percentage Information Button (mobile view only) */}
                                <div className="col-6 ps-2 text-start">
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => {
                                            setDifferencePercentageModalShow(
                                                true
                                            );
                                        }}
                                        className={
                                            "mb-3 text-nowrap " +
                                            styles.btnMainOutline
                                        }
                                    >
                                        % Difference{" "}
                                        <QuestionCircleFill color="#999" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
                {/* Chart */}
                <div
                    className="mb-3 d-flex flex-wrap flex-column d-none"
                    id="boatChartContainer"
                >
                    <canvas id="boat-chart" className="w-100"></canvas>
                </div>
            </Container>
        </main>
    );
}

export default ComparisonPage;
