/**
 * @author Zachary Fedorak
 * @version MAR-11-2022
 * @description Caret Up Filled Bootstrap Icon
 * @see https://icons.getbootstrap.com/icons/caret-up-fill/
 */

//React
import React from "react";
//css
import "./CaretFilled.css";
function CaretUpFilledIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="caretfilledsvg"
            viewBox="0 0 16 16"
        >
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
        </svg>
    );
}

export default CaretUpFilledIcon;
