/**
 * @author Robert Kelly
 * @author Jonathan Kryvenchuk
 * @author Zachary Fedorak
 * @author Omer Ahmed
 * @author Afi Ali
 * @version APR-06-2022
 * @description This component is used to search for a boat to be compared..
 */

//React Router
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import InvertedInstagramIcon from "../icons/InvertedInstagramIcon";
import InvertedEnvelopeIcon from "../icons/InvertedEnvelopeIcon";
import styles from "./Footer.styles.module.css";

function Footer() {
    return (
        <footer className={styles.footer + " sticky-lg-top mt-auto"}>
            <div className="max-container mx-auto">
                <Container fluid className="py-3">
                    <div className="d-flex justify-content-between align-items-start my-auto ">
                        <div className="d-flex flex-column flex-sm-row align-items-center">
                            <a
                                href="https://www.instagram.com/surfselect/"
                                title="SurfSelect Instagram"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <InvertedInstagramIcon
                                    width="48"
                                    height="48"
                                    color="#394955"
                                    className="iconsvg"
                                />
                            </a>
                            <Link
                                className="ms-sm-3 mt-3 mt-sm-0"
                                to="/contact"
                                title="Contact Us"
                            >
                                <InvertedEnvelopeIcon
                                    width="48"
                                    height="48"
                                    color="#394955"
                                    className="iconsvg"
                                />
                            </Link>
                        </div>
                        <div className={"ms-3 align-self-sm-center"}>
                            <p className={styles.copyright + " m-0"}>
                                <span className="fw-bold">Disclaimer:</span>{" "}
                                Boat specifications are based on information
                                available from manufacturer websites at the time
                                of collection and is subject to error and change
                                at any time.{" "}
                                <a
                                    href="./privacy.txt"
                                    className={"fw-bold " + styles.footerLink}
                                >
                                    Privacy Policy
                                </a>
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
        </footer>
    );
}

export default Footer;
