/**
 * @author Fred-III_Whiteman
 * @version APR-05-2022
 * @visiblename List Modal Messages
 * @Description This page will list all of the Modal Messages on the Comparison page and allow the user to select one to edit.
 */

//#region Imports
// React
import React, { useEffect } from "react";

// React Router
import { useNavigate } from "react-router-dom";

// Bootstrap
import { Container } from "react-bootstrap";

// Firebase
import { auth } from "../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import DataFetcher from "../../components/dataFetcher/DataFetcher";

// Table
import { Paginated } from "../../components/table/PaginatedTable";

//  Button component
import { DefaultButton } from "../../components/button/Button";

// CSS
import Styles from "./ListModalMessages.module.css";
//#endregion

function ListModalMessages() {
    //#region Authenticate login - if user isn't valid, redirect to home page
    // eslint-disable-next-line no-unused-vars
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/");
        }
    }, [user, loading]);
    //#endregion

    let data = DataFetcher("ModalMessages");

    const routeData = (key, header, message) => {
        navigate("/popups/manage", {
            state: {
                Key: key,
                Header: header,
                Message: message,
            },
        });
    };

    const columns = [
        {
            Header: "Header",
            accessor: "Header",
        },
        {
            Header: "Message",
            accessor: "Message",
        },
        {
            Header: "Select",
            accessor: "select",
            Cell: (info) => {
                return (
                    <DefaultButton
                        label="Edit"
                        title="customBtn"
                        handleClick={() => {
                            routeData(
                                info.row.original.key,
                                info.row.original.Header,
                                info.row.original.Message
                            );
                        }}
                    >
                        Edit
                    </DefaultButton>
                );
            },
        },
    ];

    return (
        <main className="max-container m-auto">
            <Container fluid>
                <div className={Styles.popupList}>
                    <h1>All Popup Messages</h1>
                    <div className="d-flex no-wrap justify-content-between my-3">
                        <DefaultButton
                            label="Go back to the dashboard"
                            title="customBtn"
                            handleClick={() => navigate("/dashboard")}
                        />
                    </div>
                    {/* Table rendering */}
                    <Paginated data={data} columns={columns} />
                </div>
            </Container>
        </main>
    );
}

export default ListModalMessages;
