/**
 * @author Robert Kelly
 * @version MAR-08-2022
 * @description This component is used to search for a boat to be compared..
 */

// Components
import DataFetcher from "../dataFetcher/DataFetcher";

// React
import { useNavigate } from "react-router-dom";

// Bootstrap
import { InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

// Css
import styles from "./Search.styles.module.css";

function Search() {
    let navigate = useNavigate();

    let boatCollection = DataFetcher("Boat")
        .filter((boat) => {
            return boat.Visible;
        })
        .sort((boat1, boat2) => {
            let manufacturerSorted = [
                boat1.Manufacturer,
                boat2.Manufacturer,
            ].sort();
            if (boat1.Manufacturer !== boat2.Manufacturer) {
                //Ascending order
                return manufacturerSorted[0] === boat2.Manufacturer ? 1 : -1;
            } else if (boat1.Model !== boat2.Model) {
                let modelSorted = [boat1.Model, boat2.Model].sort();
                //Ascending order
                return modelSorted[0] === boat2.Model ? 1 : -1;
            } else {
                let yearSorted = [boat1.Year, boat2.Year].sort();
                //Descending order
                return yearSorted[0] === boat2.Year ? -1 : 1;
            }
        });

    const filterByFields = ["Manufacturer", "Model"];

    return (
        <InputGroup className="flex-nowrap">
            <Typeahead
                filterBy={filterByFields}
                id="searchSelectTypeahead"
                className={
                    styles.searchBox +
                    " " +
                    styles.searchSelectTypeahead +
                    " flex-fill" +
                    " boatSelectTypeahead"
                }
                inputProps={{ className: styles.searchSelect + " px-4 py-2" }}
                labelKey={(boat) => {
                    {
                        return `${
                            boat.Manufacturer ? boat.Manufacturer : ""
                        }: ${boat.Model ? boat.Model : ""}- ${
                            boat.Year ? boat.Year : ""
                        }`;
                    }
                }}
                onMenuToggle={() => {
                    if (document.querySelector("header") !== null) {
                        document
                            .querySelector("header")
                            .classList.toggle("sticky-top");
                    }
                }}
                onChange={(boat) => {
                    navigate("/comparison", { state: { boatKey: boat[0] } });
                }}
                options={boatCollection}
                placeholder="Select a boat..."
                renderMenuItemChildren={(boat) =>
                    `${boat.Manufacturer ? boat.Manufacturer : ""}: ${
                        boat.Model ? boat.Model : ""
                    } - ${boat.Year ? boat.Year.toString() : ""}`
                }
                emptyLabel={
                    <span className={styles.searchLabel}>
                        No boats found.{" "}
                        <span
                            onClick={() => {
                                navigate("/contact");
                            }}
                            className={
                                styles.searchLink + " text-decoration-underline"
                            }
                        >
                            Contact Us
                        </span>
                    </span>
                }
            />
        </InputGroup>
    );
}

export default Search;
