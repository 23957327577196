/**
 * @author Jonathan Kryvenchuk
 * @author Fred III Whiteman
 * @version APR-06-2022
 * @description This is the container for the components of the web app.
 */

// React Router
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// Components
import MainNavigation from "./components/navigation/MainNavigation";
import Footer from "./components/navigation/Footer";

// Pages
import HomePage from "./pages/Home";
import BlogPage from "./pages/Blog";
import ComparisonPage from "./pages/Comparison";
import ContactUsPage from "./pages/ContactUs";
import ReferencesPage from "./pages/References";
import EmployeePage from "./pages/employee/EmployeeLogin";
import Dashboard from "./pages/employee/Dashboard";
import ListBoats from "./pages/employee/ListBoats";
import ListBlogsPage from "./pages/employee/ListBlogs";
import BoatManagement from "./pages/employee/BoatManagement";
import BlogManagement from "./pages/employee/BlogManagement";
import ListManufacturersPage from "./pages/employee/ListManufacturer";
import ManufacturerManagement from "./pages/employee/ManufacturerManagement";
import ListModalMessages from "./pages/employee/ListModalMessages";
import ModalMessageManagement from "./pages/employee/ModalMessageManagementPage";

function App() {
    let homePageOuterClasses = "";
    if (useLocation().pathname == "/") {
        document.querySelector("body").classList.add("main-container");
        homePageOuterClasses = "vh-100";
    } else {
        document.querySelector("body").classList.remove("main-container");
    }

    return (
        <div
            className={"min-vh-100 d-flex flex-column " + homePageOuterClasses}
        >
            <MainNavigation />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/comparison" element={<ComparisonPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/blog/list" element={<ListBlogsPage />} />
                <Route path="/blog/manage" element={<BlogManagement />} />
                <Route path="/references" element={<ReferencesPage />} />
                <Route path="/contact" element={<ContactUsPage />} />
                {/* Employee/private routes */}
                <Route path="/login" element={<EmployeePage />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/boats" element={<ListBoats />} />
                <Route path="/boats/manage" element={<BoatManagement />} />
                <Route
                    path="/manufacturer"
                    element={<ListManufacturersPage />}
                />
                <Route
                    path="/manufacturer/manage"
                    element={<ManufacturerManagement />}
                />
                <Route path="/popups" element={<ListModalMessages />} />
                <Route
                    path="/popups/manage"
                    element={<ModalMessageManagement />}
                />
                <Route path="/blogs" element={<BlogManagement />} />
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            {useLocation().pathname != "/" ? <Footer /> : ""}
        </div>
    );
}

export default App;
