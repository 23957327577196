/**
 * @author Jonathan Kryvenchuk
 * @author Omer Ahmed
 * @version MAR-25-2022
 * @description This component is used to select a boat to be compared on the comparison page.
 * @param props.idNum - Used to concatenate ids when reusing component.
 * @param props.boatCollection - The entire collection of boats from the database.
 * @param props.getTypeaheadInput - Used to get a reference to this component.
 * @param props.onChangeEvent - A function to be executed when an onChange event fires.
 * @returns React Bootstrap Typeahead
 */

//Prop Types
import PropTypes from "prop-types";

//React
import { createRef, useEffect } from "react";

//React Router
import { useNavigate } from "react-router-dom";

//Bootstrap
import { Typeahead } from "react-bootstrap-typeahead";

//Styles
import styles from "./BoatSelect.styles.module.css";

function BoatSelect(props) {
    let navigate = useNavigate();
    const filterByFields = ["Manufacturer", "Model", "Year"];
    const ref = createRef();

    useEffect(() => {
        props.getTypeaheadInput(ref.current.getInput());
    }, []);

    return (
        <Typeahead
            id={"boatSelect" + props.idNum.toString() + "Typeahead"}
            filterBy={filterByFields}
            options={props.boatCollection}
            ref={ref}
            labelKey={(boat) => {
                {
                    return `${boat.Manufacturer ? boat.Manufacturer : ""}: ${
                        boat.Model ? boat.Model : ""
                    } - ${boat.Year ? boat.Year : ""}`;
                }
            }}
            renderMenuItemChildren={(boat) =>
                `${boat.Manufacturer ? boat.Manufacturer : ""}: ${
                    boat.Model ? boat.Model : ""
                } - ${boat.Year ? boat.Year.toString() : ""}`
            }
            placeholder={"Select a boat..."}
            inputProps={{
                id: "boatSelect" + props.idNum.toString(),
                tabIndex: props.tabIndex,
                className: props.inputClassName,
            }}
            className={props.className + " boatSelectTypeahead"}
            onInputChange={(value) => {
                if (value === "") {
                    props.onChangeEvent(null, props.idNum);
                }
            }}
            onMenuToggle={() => {
                if (document.querySelector("header") !== null) {
                    document
                        .querySelector("header")
                        .classList.toggle("sticky-top");
                }
            }}
            onBlur={() => {
                if (
                    document.querySelector(
                        "#boatSelect" + props.idNum.toString()
                    ) !== null
                ) {
                    document
                        .querySelector("#boatSelect" + props.idNum.toString())
                        .setAttribute("inputmode", "text");
                }
            }}
            onChange={(boat) => {
                props.onChangeEvent(
                    boat.length > 0 ? boat[0].Index : null,
                    props.idNum
                );
                ref.current.clear();

                if (
                    document.querySelector(
                        "#boatSelect" + props.idNum.toString()
                    ) !== null
                ) {
                    document
                        .querySelector("#boatSelect" + props.idNum.toString())
                        .setAttribute("inputmode", "none");
                }
            }}
            emptyLabel={
                <span className={styles.boatSelectLabel}>
                    No boats found.{" "}
                    <span
                        onClick={() => {
                            navigate("/contact");
                        }}
                        className={
                            styles.boatSelectLink + " text-decoration-underline"
                        }
                    >
                        Contact Us
                    </span>
                </span>
            }
        />
    );
}

BoatSelect.propTypes = {
    boatCollection: PropTypes.array,
    idNum: PropTypes.number,
    onChangeEvent: PropTypes.func,
    tabIndex: PropTypes.string,
    inputClassName: PropTypes.string,
    className: PropTypes.string,
    getTypeaheadInput: PropTypes.func,
};

BoatSelect.defaultProps = {
    boatCollection: ["Loading..."],
    idNum: 0,
    onChangeEvent: () => {},
    tabIndex: "0",
    inputClassName: "",
    className: "",
    getTypeaheadInput: () => {},
};

export default BoatSelect;
