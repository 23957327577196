/**
 * @author Russel Benito
 * @author Fred III Whiteman
 * @version APR-12-2022
 * @visibleName Blog Management Page
 * @description This page is used for the admin to manage the blog posts by updating/adding/deleting posts.
 */

//#region imports
//Bootstrap
import {
    Form,
    FormControl,
    FormGroup,
    FormLabel,
    Container,
    Alert,
    Stack,
} from "react-bootstrap";

//React
import React, { useEffect, useState } from "react";

// React Router
import { useLocation, useNavigate } from "react-router-dom";

//Firebase
import { auth } from "../../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import AddUpdate from "../../components/crudFunctions/AddUpdate";
import DeleteDoc from "../../components/crudFunctions/DeleteDoc";

// Button
import { DefaultButton, SubmitButton } from "../../components/button/Button";

// Markdown
import Markdown from "../../components/markdown/Markdown";

// CSS
import Styles from "./ManagementForm.module.css";
import MdStyles from "./BlogManagement.module.css";
//#endregion

// #region constants
// Valid Title lengths
const MIN_TITLE_LENGTH = 5;
const MAX_TITLE_LENGTH = 100;

// Valid author length
const MIN_AUTHOR_LENGTH = 5;
const MAX_AUTHOR_LENGTH = 50;

// Valid position length
const MIN_POSITION = 0;

// #endregion

// #region Helper Functions
function saveBlogData(docKey, datePublished, lastUpdated) {
    // info to pass
    const data = {
        collection: "Posts",
        docId: docKey,
        info: {
            Title: document.querySelector("#title").value,
            Body: document.querySelector("textarea").value,
            DatePublished: datePublished,
            LastUpdated: lastUpdated,
            Author: document.querySelector("#author").value,
            Position: parseInt(document.querySelector("#position").value),
            Published:
                document.querySelector(".publishedCheck").firstChild.checked,
        },
    };

    // add data
    const message = AddUpdate(data);
    return message;
}

//Clear form fields
function clearFields() {
    document.querySelector("#title").value = "";
    document.querySelector(".section-container").value = "";
    document.querySelector("#author").value = "";
    document.querySelector(".publishedCheck").firstChild.checked = false;
    document.querySelector(".custom-html-style").innerHTML = "";

    // clear the alert
    document.querySelector(".successMessage").innerText = "";
    document.querySelector(".errorMessage").innerText = "";
    document.querySelector(".errorList").innerHTML = "";
}

// Get current date
function getCurrentDate() {
    const newDate = new Date();
    return `${newDate.getFullYear()}-${
        newDate.getMonth() + 1
    }-${newDate.getDate()}`;
}

// #endregion

function BlogManagementPage() {
    //#region Authentication and declarations
    // eslint-disable-next-line no-unused-vars
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [validated, setValidated] = useState(false);
    const [isHidden, setHidden] = useState(true);
    const [shouldHide, setHide] = useState(true);

    let returnMessage = {
        success: "",
        failure: "",
    };
    let errorMessage;
    let validationMessages = {
        blogTitle: "",
        blogBody: "",
        blogAuthor: "",
        blogPosition: "",
    };

    useEffect(() => {
        if (loading) {
            return;
        }
        if (!user) {
            return navigate("/");
        }
    }, [user, loading]);

    //#endregion

    // #region Form Validation
    function validateBlog() {
        validateTitle();
        validateAuthor();
        validatePosition();

        return validationMessages;
    }

    function validateTitle() {
        const input = document.getElementById("title");
        const validityState = input.validity;

        const valueMissingMessage = "Title is a required field.";
        const tooShortMessage = `Title must be at least ${MIN_TITLE_LENGTH} characters long.`;
        const tooLongMessage = `Title must be at most ${MAX_TITLE_LENGTH} characters long.`;

        if (validityState.valueMissing) {
            input.setCustomValidity(valueMissingMessage);
            validationMessages.blogTitle = valueMissingMessage;
        } else if (validityState.tooShort) {
            input.setCustomValidity(tooShortMessage);
            validationMessages.blogTitle = tooShortMessage;
        } else if (validityState.tooLong) {
            input.setCustomValidity(tooLongMessage);
            validationMessages.blogTitle = tooLongMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.blogTitle = "";
        }

        input.reportValidity();
    }

    function validateAuthor() {
        const input = document.getElementById("author");
        const validityState = input.validity;

        const valueMissingMessage = "Author is a required field.";
        const tooShortMessage = `Author must be at least ${MIN_AUTHOR_LENGTH} characters long.`;
        const tooLongMessage = `Author must be at most ${MAX_AUTHOR_LENGTH} characters long.`;

        if (validityState.valueMissing) {
            input.setCustomValidity(valueMissingMessage);
            validationMessages.blogAuthor = valueMissingMessage;
        } else if (validityState.tooShort) {
            input.setCustomValidity(tooShortMessage);
            validationMessages.blogAuthor = tooShortMessage;
        } else if (validityState.tooLong) {
            input.setCustomValidity(tooLongMessage);
            validationMessages.blogAuthor = tooLongMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.blogAuthor = "";
        }

        input.reportValidity();
    }

    function validatePosition() {
        const input = document.getElementById("position");
        const validityState = input.validity;

        const valueMissingMessage = "The blog position is a required field.";
        const rangeUnderflowMessage = `The blog position must be at greater than ${MIN_POSITION}`;

        if (validityState.valueMissing) {
            input.setCustomValidity(valueMissingMessage);
            validationMessages.blogPosition = valueMissingMessage;
        } else if (validityState.rangeUnderflow) {
            input.setCustomValidity(rangeUnderflowMessage);
            validationMessages.blogPosition = rangeUnderflowMessage;
        } else {
            input.setCustomValidity("");
            validationMessages.blogPosition = "";
        }

        input.reportValidity();
    }
    // #endregion

    // #region Form Handling
    //Save button event (Add/Update)
    const onSave = async (event) => {
        event.preventDefault();

        // declarations
        let errorHeading = document.querySelector(".errorMessage");
        let errorList = document.querySelector(".errorList");

        const form = event.currentTarget;
        const result = await confirm(
            "Are you sure you want to save this blog?"
        );

        //clear old errors
        errorHeading.innerText = "";
        errorList.innerHTML = "";
        setHide(true);
        setHidden(true);

        if (result) {
            // validate form
            validationMessages = validateBlog();
            if (form.checkValidity() === false) {
                event.stopPropagation();
            }

            // shows validation
            setValidated(true);

            // figure out published/updated dates
            let datePublished;
            let lastUpdated;
            let docKey;
            let isPublished =
                document.querySelector(".publishedCheck").firstChild.checked;

            // If post is new
            if (location.state.Key === "") {
                // If making new post but not publishing
                if (!isPublished) {
                    datePublished = "Not Published";
                    lastUpdated = "Not Published";
                } else {
                    // If making new post and publishing
                    datePublished = getCurrentDate();
                    lastUpdated = getCurrentDate();
                }
            }
            // If post is being updated
            else {
                if (
                    (location.state.DatePublished != "Not Published" &&
                        isPublished) ||
                    !isPublished
                ) {
                    // republishing an old post
                    // Toggling a published post to unpublished
                    // If saving an unpublished post and keeping it unpublished
                    datePublished = location.state.DatePublished;
                    lastUpdated = getCurrentDate();
                } else {
                    // If publishing an unpublished post or
                    // Updating a published post, keeping it published
                    datePublished = getCurrentDate();
                    lastUpdated = getCurrentDate();
                }
            }

            // get the doc key here if it exists
            if (location.state) {
                docKey = location.state.Key;
            } else {
                docKey = "";
            }

            // check there are no errors
            if (
                Object.values(validationMessages).every(
                    (x) => x === "" || x === null
                )
            ) {
                returnMessage = saveBlogData(
                    docKey,
                    datePublished,
                    lastUpdated
                );
            } else {
                errorHeading.innerText = "Input not valid: ";
                setHide(false);
                // display error messages
                Object.values(validationMessages).map((errorMessage) => {
                    if (errorMessage != "") {
                        let li = document.createElement("li");
                        errorList.append(li);
                        li.innerText = errorMessage;
                    }
                });
            }
        }

        if (returnMessage.success != "") {
            setHidden(false);
            document.querySelector(".successMessage").innerText =
                returnMessage.success;
        } else if (returnMessage.failure != "") {
            setHide(false);
            document.querySelector(".errorMessage").innerText =
                returnMessage.failure;
        }
    };

    // Delete button event
    const onDelete = async (event) => {
        event.preventDefault();

        const result = await confirm(
            "Are you sure you want to delete this Blog?"
        );

        if (result) {
            // check that blog exists
            if (location.state) {
                // info to pass
                const data = {
                    collection: "Posts",
                    docId: location.state.Key,
                };

                returnMessage = DeleteDoc(data);
                setValidated(false);
                clearFields();
            } else {
                errorMessage = "No Blog selected.";
            }
        }

        if (returnMessage.success != "") {
            setHidden(false);
            document.querySelector(".successMessage").innerText = returnMessage;
        } else if (errorMessage != "") {
            setHide(false);
            document.querySelector(".errorMessage").innerText = errorMessage;
        } else if (returnMessage.failure != "") {
            setHide(false);
            document.querySelector(".errorMessage").innerText = returnMessage;
        }
    };

    // Clear button event
    const onClear = (event) => {
        event.preventDefault();
        setValidated(false);
        setHidden(true);
        setHide(true);

        clearFields();
    };

    // #endregion

    return (
        <main className="max-container m-auto">
            <Container fluid>
                <h1>Blog Post Management Page</h1>
                <div className="d-flex justify-content-center">
                    <DefaultButton
                        label="Go back to blog page list"
                        title="customBtn"
                        handleClick={() => navigate("/blog/list")}
                    />
                </div>

                <div className="mb-3">
                    <Alert
                        className="successMessage my-3"
                        variant="success"
                        hidden={isHidden}
                    ></Alert>
                    <Alert
                        className="my-3"
                        variant="danger"
                        hidden={shouldHide}
                    >
                        <Alert.Heading className="errorMessage"></Alert.Heading>
                        <ul className="errorList"></ul>
                    </Alert>

                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={onSave}
                        className={Styles.form + " mt-3 " + MdStyles.form}
                    >
                        {/* Blog Title section */}
                        <FormGroup className="my-3">
                            <FormLabel>Title:</FormLabel>
                            <FormControl
                                type="text"
                                id="title"
                                defaultValue={
                                    location.state ? location.state.Title : ""
                                }
                                required
                                minLength={MIN_TITLE_LENGTH}
                                maxLength={MAX_TITLE_LENGTH}
                            ></FormControl>
                        </FormGroup>
                        {/* Blog Body section */}
                        <Markdown
                            existingText={
                                location.state ? location.state.Body : ""
                            }
                        />
                        {/* Author section */}
                        <FormGroup className="my-3">
                            <FormLabel>Author: </FormLabel>
                            <FormControl
                                type="text"
                                id="author"
                                defaultValue={
                                    location.state ? location.state.Author : ""
                                }
                                required
                                minLength={MIN_AUTHOR_LENGTH}
                                maxLength={MAX_AUTHOR_LENGTH}
                            ></FormControl>
                        </FormGroup>
                        {/* Position section */}
                        <FormGroup className="my-3">
                            <FormLabel>Position: </FormLabel>
                            <FormControl
                                type="number"
                                id="position"
                                defaultValue={location.state.Position}
                                required
                                min={0}
                            ></FormControl>
                        </FormGroup>
                        {/* Published section */}
                        <FormGroup className="my-3 d-flex gap-3">
                            <FormLabel>Published: </FormLabel>
                            <Form.Check
                                inline
                                type="switch"
                                className="publishedCheck"
                                defaultChecked={
                                    location.state
                                        ? location.state.Published
                                        : false
                                }
                            ></Form.Check>
                        </FormGroup>
                        {/* Buttons */}
                        <Stack
                            direction="horizontal"
                            gap={3}
                            className="buttonGroup"
                        >
                            <SubmitButton label="Save Blog" title="customBtn">
                                Save
                            </SubmitButton>
                            <DefaultButton
                                label="Delete"
                                title="deleteBtn"
                                handleClick={onDelete}
                            >
                                Delete
                            </DefaultButton>
                            <DefaultButton
                                label="Clear"
                                title="clearBtn"
                                handleClick={onClear}
                            >
                                Clear
                            </DefaultButton>
                        </Stack>
                    </Form>
                </div>
            </Container>
        </main>
    );
}

export default BlogManagementPage;
