/**
 * @author Jonathan Kryvenchuk
 * @version MAR-29-2022
 * @description This component is used to display a table header and toggle caret for a mobile view.
 * @param props.header - Table header text to be displayed
 * @param props.children - Children to be included in header text
 * @param props.id - Id to be assigned to caret spans for toggle
 * @param props.downCaretClassName - Class to be applied to down caret
 * @param props.upCaretClassName - (default: d-none) Class to be applied to up caret
 * @returns Flexed Spans with toggleable Carets (by using toggleMobileView function)
 */

//Prop Types
import PropTypes from "prop-types";

//Icons
import CaretDownFilled from "../icons/CaretDownFilled";
import CaretUpFilled from "../icons/CaretUpFilled";

function TableHeaderCaret(props) {
    return (
        <span className="d-flex">
            <span className="col-1"></span>
            <span className="col">
                {props.header}
                {props.children}
            </span>
            <span
                id={props.id + "CaretDown"}
                className={props.downCaretClassName + " col-1"}
            >
                <CaretDownFilled />
            </span>
            <span
                id={props.id + "CaretUp"}
                className={props.upCaretClassName + " col-1"}
            >
                <CaretUpFilled />
            </span>
        </span>
    );
}

TableHeaderCaret.propTypes = {
    children: PropTypes.node,
    header: PropTypes.string,
    id: PropTypes.string,
    downCaretClassName: PropTypes.string,
    upCaretClassName: PropTypes.string,
};

TableHeaderCaret.defaultProps = {
    children: "",
    header: "",
    id: "",
    downCaretClassName: "",
    upCaretClassName: "d-none",
};

export default TableHeaderCaret;
