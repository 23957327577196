/**
 * @author Robert Kelly
 * @author Jonathan Kryvenchuk
 * @author Zachary Fedorak
 * @author Omer Ahmed
 * @author Afi Ali
 * @version APR-06-2022
 * @visibleName Home Page
 * @description This is the main page for the site. It includes a search bar where users can search for boats, then they will be redirected to the comparison page.
 */

//Components
import Search from "../components/homepage/Search";
import InvertedInstagramIcon from "../components/icons/InvertedInstagramIcon";
import InvertedEnvelopeIcon from "../components/icons/InvertedEnvelopeIcon";
import HomePageLogo from "../components/icons/HomePageLogo";

//React Router
import { Link } from "react-router-dom";

//Bootstrap
import { Container, Row } from "react-bootstrap";

// css
import "./Home.css";

function HomePage() {
    return (
        /* Main fills available space */
        <main className="flex-fill">
            <Container className="h-100 max-container" fluid>
                <Row className="h-100 align-items-center">
                    <div className="h-100 text-center d-flex flex-column justify-content-between">
                        <div className="flex-fill"></div>
                        <div className="flex-fill d-flex flex-column justify-content-center">
                            {/* Title of the Page */}
                            <HomePageLogo />
                            {/* Search Bar */}
                            <div className="col-12 col-sm-10 col-lg-6 mx-auto mb-3">
                                <Search />
                            </div>
                        </div>
                        <footer className="flex-fill d-flex flex-column justify-content-evenly">
                            <div className="footer">
                                <div className="icons mb-3">
                                    <a
                                        className="instagramicon"
                                        href="https://www.instagram.com/surfselect/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <InvertedInstagramIcon
                                            width="55"
                                            height="55"
                                            color="#fff"
                                        />
                                    </a>
                                    <Link to="/contact">
                                        <InvertedEnvelopeIcon
                                            width="55"
                                            height="55"
                                            color="#fff"
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="copyright mb-3">
                                <p className="mb-1">
                                    <span className="fw-bold">Disclaimer:</span>{" "}
                                    Boat specifications are based on information
                                    available from manufacturer websites at the
                                    time of collection and is subject to error
                                    and change at any time.
                                </p>
                                <a
                                    href="./privacy.txt"
                                    className={"fw-bold link-light"}
                                >
                                    Privacy Policy
                                </a>
                            </div>
                        </footer>
                    </div>
                </Row>
            </Container>
        </main>
    );
}

export default HomePage;
